<template>
  <header class="header">
    <div class="header__logoContainer">
      <router-link :to="{ name: RouteName.HOME }">
        <svg class="header__logo">
          <use xlink:href="#logo-icon--sprite" />
        </svg>
      </router-link>
    </div>

    <div class="header__content">
      <div class="header__navList" v-if="0">
        <nav class="header__nav" v-for="(navElem, i) in menu" :key="navElem">
          <router-link class="header__link" :to="'/'">
            {{ navElem }}
          </router-link>
          <div class="header__line" v-if="i !== menu.length - 1" />
        </nav>
      </div>

      <div class="header__personContainer">
        <CommonButtonPrimary @click="openSignInModal" v-if="!userFullName">
          Войти
        </CommonButtonPrimary>

        <div class="header__person bold-text" v-else>{{ userFullName }}</div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";
import { useVfm } from "vue-final-modal";
import { RouteName } from "@/constants";
import { menu } from "./constants";
import { useRoute } from "vue-router";
import store from "@/store";

import "@/assets/img/icons/logo-icon.svg?sprite";
import "@/assets/img/icons/message-icon.svg?sprite";
import "@/assets/img/icons/bell-icon.svg?sprite";

export default defineComponent({
  name: "HomeHeader",
  components: {
    CommonButtonPrimary,
  },

  setup() {
    const route = useRoute();

    const vfm = useVfm();

    const me = computed(() => store.getters.userStore.me);

    const userFullName = computed(() => me.value?.fullname);

    const isShownNotifications = ref(false);

    const isHomeMain = computed(() => route?.name === RouteName.HOME);

    const openSignInModal = () => vfm.open("signInModal");

    onMounted(() => {
      store.dispatch.userStore.getMe();
    });

    return {
      userFullName,
      isHomeMain,
      menu,
      RouteName,
      isShownNotifications,
      openSignInModal,
    };
  },
});
</script>

<style lang="stylus" scoped>
.header {
  width: 100%;
  position: sticky;
  z-index: 3;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 20px 15px;
  background-color: $colorFiller;
  border-bottom: 3px solid $colorMediumGrey;

  &__logo {
    width: 130px;
    height: 60px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1vw;
  }

  &__navList {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__iconsContainer {
    display: flex;
    align-items: center;
    gap: .6vw;
  }

  &__icon {
    width: 20px;
    height: 20px;

    &:hover {
      opacity: .6;
      cursor: pointer;
    }
  }

  &__link {
    text-decoration: none;
  }

  &__line {
    width: 1px;
    height: 20px;
    background-color: $colorFontBase;
    margin: 0 10px;
  }
  &__nav {
    display: flex;
  }

  &__person {
    font-weight: bold;
  }

  +mediaTablet() {
    padding: 2.1vw 13.6vw;

    &__logo {
      width: 15vw;
    }

    &__icon {
      width: 30px;
      height: 30px;
    }
  }
}
</style>
