<template>
  <div :class="{ dpo: hasDpo }">
    <router-view />

    <ModalTestRules />
    <ModalTrajectory />
    <ModalGoToEducation />
    <ModalSuccess />
    <ModalFail />

    <div class="base-wrapper">
      <div class="base-container">
        <LayoutHeader />

        <div class="base-content">
          <CommonNavigation
            class="base-navigation"
            :nav-list="
              hasDpo ? navDpoList : hasCurator ? navCuratorList : navList
            "
            :is-query="hasCurator"
            @toggleTab="toggleCuratorTab, $emit('toggleTab', $event)"
          />

          <slot />
        </div>

        <LayoutFooter />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { navList, navDpoList, navCuratorList } from "./constants";
import { LayoutFooter } from "../LayoutFooter/index";
import { LayoutHeader } from "../LayoutHeader/index";
import { CommonNavigation } from "@/components/CommonNavigation/index";
import { ModalTestRules } from "@/modals/ModalTestRules/index";
import { ModalTrajectory } from "@/modals/ModalTrajectory/index";
import { ModalGoToEducation } from "@/modals/ModalGoToEducation/index";
import { ModalSuccess } from "@/modals/ModalSuccess/index";
import { ModalFail } from "@/modals/ModalFail/index";
import { useRoute, useRouter } from "vue-router";
import { RouteName } from "@/constants";

export default defineComponent({
  name: "LayoutBase",
  components: {
    LayoutHeader,
    LayoutFooter,
    CommonNavigation,
    ModalTestRules,
    ModalTrajectory,
    ModalGoToEducation,
    ModalSuccess,
    ModalFail,
  },

  emits: ["toggleTab"],

  setup() {
    const route = useRoute();
    const router = useRouter();

    const hasDpo = computed(() => route.matched[0]?.name === RouteName.DPO);
    const hasCurator = computed(
      () => route.matched[0]?.name === RouteName.CURATOR
    );

    const toggleCuratorTab = async (id: number) => {
      if (hasCurator.value) {
        await router.push({ query: { ...route?.query, degree_id: id } });
      }
    };

    return {
      hasDpo,
      hasCurator,
      navDpoList,
      navList,
      navCuratorList,
      toggleCuratorTab,
    };
  },
});
</script>

<style lang="stylus" scoped>
.base-wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.base-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
}

.base-content {
  flex-grow: 1;
  width: 100%;
  padding: 20px 15px 40px;
  +mediaTablet() {
    padding: 1.9vw 13.6vw 7.8vw;
    margin-left: -12px;
    margin-right: -12px;
  }
}

.base-navigation {
  margin-bottom: 50px;

  +mediaTablet() {
    margin-bottom: 5vw;
  }
}
</style>
