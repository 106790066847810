<template>
  <LayoutHomepage>
    <CommonIntro />

    <div class="page-section">
      <div class="nav-overflow">
        <nav class="tabs-menu">
          <ul class="nav nav-tabs">
            <li
              class="nav-item intro__nav"
              v-for="item in homeNavList"
              :key="item.label"
              v-show="
                !(
                  (item.label === 'Документы' ||
                    item.label === 'Приемная кампания') &&
                  hasCourses
                )
              "
            >
              <router-link
                v-if="
                  !(
                    (item.label === 'Документы' ||
                      item.label === 'Приемная кампания') &&
                    hasCourses
                  )
                "
                :class="[
                  'nav-link',
                  {
                    active:
                      pathName(
                        item.pathOrdinatName,
                        item.pathAspirantName,
                        item.pathCoursesName
                      ).value === route?.name,
                  },
                ]"
                :to="{
                  name: pathName(
                    item.pathOrdinatName,
                    item.pathAspirantName,
                    item.pathCoursesName
                  ).value,
                  query: route.query,
                }"
                @click="toggleBodyStyle"
              >
                {{ item.label }}
              </router-link>
            </li>
          </ul>
        </nav>
      </div>

      <h2 class="section-title">Приемная компания</h2>
      <CommonQuestion
        :question="item.header"
        isSlotAnswer
        v-for="item in programOrd?.reception_company?.desc"
        :key="item.header"
      >
        <div class="answer" v-html="item.text" />
      </CommonQuestion>
    </div>

    <div class="docsList">
      <a
        class="docsList__item"
        v-for="(item, i) in programOrd?.reception_company?.reception_bar_1"
        :key="item.title"
        :href="item?.list[0]"
        target="_blank"
      >
        <svg class="svg-icon" v-if="i === 0 || i === 6">
          <use xlink:href="@/assets/img/sprite.svg#docs1"></use>
        </svg>
        <svg class="svg-icon" v-else-if="i === 1 || i === 7">
          <use xlink:href="@/assets/img/sprite.svg#docs2"></use>
        </svg>
        <svg class="svg-icon" v-else-if="i === 2 || i === 8">
          <use xlink:href="@/assets/img/sprite.svg#docs3"></use>
        </svg>
        <svg class="svg-icon" v-else-if="i === 3 || i === 9">
          <use xlink:href="@/assets/img/sprite.svg#docs4"></use>
        </svg>
        <svg class="svg-icon" v-else-if="i === 4 || i === 10">
          <use xlink:href="@/assets/img/sprite.svg#docs5"></use>
        </svg>
        <svg class="svg-icon" v-else-if="i === 5 || i === 11">
          <use xlink:href="@/assets/img/sprite.svg#docs6"></use>
        </svg>
        <b class="docsList__title">{{ item.title }}</b>
      </a>
    </div>

    <CommonApplication />
  </LayoutHomepage>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from "vue";
import { LayoutHomepage } from "@/layouts/LayoutHomepage";
import { basicFetch } from "@/store/storeUtils";
import { api } from "@/utils/apiInstance";
import { CommonApplication } from "./components/CommonApplication";
import { CommonIntro } from "./components/CommonIntro";
import { useRouter, useRoute } from "vue-router";
import { homeNavList } from "./constants";
import { RouteName } from "@/constants";
import { CommonQuestion } from "@/components/CommonQuestion";
import store from "@/store";

export default defineComponent({
  name: "HomeAdmissionPage",

  components: {
    LayoutHomepage,
    CommonApplication,
    CommonIntro,
    CommonQuestion,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();

    const programOrd = computed(() => store.getters.programsStore.data);
    const showDiscipline = ref(1);

    const hasOrdinat = computed(() => useRoute()?.path.includes("ordinat"));
    const hasAspirant = computed(() => route?.path.includes("aspirant"));
    const hasCourses = computed(() => route?.path.includes("courses"));

    const bodyStyleName = computed(() => {
      if (route?.name === RouteName.HOME_ORDINAT_ADMISSION) {
        return "ordinat";
      } else if (route?.name === RouteName.HOME_ASPIRANT_ADMISSION) {
        return "aspirant";
      }
      return "courses";
    });

    const queryId = computed(() => route?.query?.id);

    const programId = computed(() => {
      if (queryId.value) return Number(queryId.value);

      if (route.path.includes("ordinat")) {
        return 1;
      } else if (route.path.includes("aspirant")) {
        return 2;
      }

      return 4;
    });

    const hasShowDisciplines = (id: number) =>
      computed(() => Number(route.query.id) === id);

    const goToDisciplines = (id: number) => {
      router.push({ query: { id } });
      showDiscipline.value = id;
    };

    const pathName = (ordinatPath: any, aspirantPath: any, coursesPath: any) =>
      computed(() => {
        if (hasOrdinat.value) {
          return ordinatPath;
        } else if (hasAspirant.value) {
          return aspirantPath;
        }
        return coursesPath;
      });

    const toggleBodyStyle = () => {
      document.body.classList.add(bodyStyleName.value);
    };

    onMounted(async () => {
      toggleBodyStyle();

      store.dispatch.programsStore.getPrograms({ program_id: programId.value });
    });

    return {
      hasCourses,
      programOrd,
      showDiscipline,
      homeNavList,
      route,
      toggleBodyStyle,
      pathName,
      goToDisciplines,
      hasShowDisciplines,
    };
  },
});
</script>

<style lang="stylus" scoped>
.header {
  &__icon {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }
}
.docsList {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 40px;
  padding: 40px;
  background-color: $colorLightGrey;

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    background-color: $colorFiller;
    text-align: center;
    border-radius: 10px;
    text-decoration: none;
  }

  +mediaTablet() {
    grid-template-columns: repeat(3, 1fr);
    padding: 40px 15%;
  }
}
.svg-icon {
  width: 70px;
  height: 70px;
}
</style>
