import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3730c00c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "commonNavigation"
}
const _hoisted_2 = {
  key: 1,
  class: "commonNavigation"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (!_ctx.isQuery)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navList, (item, i) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            to: { name: item.pathName },
            class: _normalizeClass([
        'commonNavigation__item',
        { commonNavigation__item_active: _ctx.isThisPage(i).value },
      ]),
            key: `nav-item-${i}`,
            onMousedown: _ctx.mousedown,
            onMouseup: _ctx.mouseup
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.label), 1)
            ]),
            _: 2
          }, 1032, ["to", "class", "onMousedown", "onMouseup"]))
        }), 128))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navList, (item, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass([
        'commonNavigation__item',
        {
          commonNavigation__item_active: _ctx.isThisQuery(
            item?.pathName,
            item?.queryName
          ).value,
        },
      ]),
            key: `nav-item-${i}`,
            onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.mousedown && _ctx.mousedown(...args))),
            onMouseup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.mouseup && _ctx.mouseup(...args))),
            onClick: ($event: any) => (_ctx.goToTab(item))
          }, _toDisplayString(item.label), 43, _hoisted_3))
        }), 128))
      ]))
}