<template>
  <div class="CommonFormItemSecond">
    <div class="CommonFormItemSecond__titleBox">
      <h3 class="CommonFormItemSecond__title">{{ title }}</h3>

      <div class="CommonFormItemSecond__arrowsBox" v-if="hasHiding">
        <svg
          class="CommonFormItemSecond__arrow"
          v-if="isOpen || !hasHiding"
          @click="toggleOpened"
        >
          <use xlink:href="#arrow-second-top-icon--sprite" />
        </svg>
        <svg class="CommonFormItemSecond__arrow" v-else @click="toggleOpened">
          <use xlink:href="#arrow-second-bottom-icon--sprite" />
        </svg>

        <slot name="suffix" />
      </div>
    </div>
    <slot v-if="isOpen || !hasHiding" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, type PropType } from "vue";
import type { CommonFormItemSecondProps } from "./types";

export default defineComponent({
  name: "CommonFormItemSecondSecond",

  props: {
    title: {
      type: String as PropType<CommonFormItemSecondProps["title"]>,
    },
    hasHiding: {
      type: Boolean as PropType<CommonFormItemSecondProps["hasHiding"]>,
      default: false,
      required: false,
    },
  },

  setup() {
    const isOpen = ref(false);

    const toggleOpened = () => (isOpen.value = !isOpen.value);

    return {
      isOpen,
      toggleOpened,
    };
  },
});
</script>

<style lang="stylus" scoped>
.CommonFormItemSecond {
  padding: 15px;
  border-radius: 10px;
  background-color: $colorFiller;

  &__titleBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  &__title {
    font-weight: normal;
  }

  &__arrowsBox {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  &__arrow {
    width: 32px;
    height: 32px;
    &:hover {
      cursor: pointer;
      opacity: .6;
    }
  }
}
</style>
