<template>
  <CommonFormItem
    :title="semesterTitle(item[0]).value"
    has-hiding
    v-for="(item, i) in groupedSyllabus"
    :key="item?.id"
  >
    <div class="curatorEducationPlan__disciplineList">
      <CommonFormItemSecond
        :title="discipline?.discipline?.title"
        has-hiding
        v-for="(discipline, index) in item"
        :key="discipline?.discipline?.title"
        class="curatorEducationPlan__formItemSec"
      >
        <template #suffix>
          <svg
            class="curatorEducationPlan__bask"
            @click="removeSyllabus(discipline)"
          >
            <use xlink:href="#bask-icon--sprite" />
          </svg>
        </template>

        <CommonSelect
          class="curatorEducationPlan__select"
          :select-options="mappedDisciplines"
          v-model="formModel.pickedDiscipline"
          v-if="!discipline?.discipline?.title"
          @onHideSelect="createSyllabus($event, discipline?.semester?.id)"
        />

        <div v-else class="curatorEducationPlan__disciplineList">
          <CommonFormItem title="Лекции" hasHiding>
            <div class="curatorEducationPlan__formContent">
              <div
                class="curatorEducationPlan__list"
                v-for="(lecture, indx) in discipline.lectures"
                :key="`goal-${indx}`"
              >
                <CommonSelect
                  class="curatorEducationPlan__select"
                  :select-options="mappedLectures"
                  v-model="lecture.title"
                  :value="discipline.lectures[indx]?.title"
                  @onHideSelect="addLecture(discipline.id, $event)"
                />
                <svg
                  class="curatorEducationPlan__bask"
                  @click="removeLecture(discipline.id, lecture.id)"
                >
                  <use xlink:href="#bask-icon--sprite" />
                </svg>
              </div>

              <svg
                class="curatorEducationPlan__plus"
                @click="pushNewLecture(i, index)"
              >
                <use xlink:href="#plus-curator--sprite" />
              </svg>
            </div>
          </CommonFormItem>

          <CommonFormItem title="Тесты" hasHiding>
            <div class="curatorEducationPlan__formContent">
              <div
                class="curatorEducationPlan__list"
                v-for="(test, indx) in discipline.tests"
                :key="`goal-${indx}`"
              >
                <CommonSelect
                  class="curatorEducationPlan__select"
                  :select-options="mappedQuizzes"
                  v-model="test.name"
                  :value="discipline.tests[indx]?.name"
                  @onHideSelect="addQuizze(discipline.id, $event)"
                />
                <svg
                  class="curatorEducationPlan__bask"
                  @click="removeQuizze(discipline.id, test.id)"
                >
                  <use xlink:href="#bask-icon--sprite" />
                </svg>
              </div>

              <svg
                class="curatorEducationPlan__plus"
                @click="pushNewQuizze(i, index)"
              >
                <use xlink:href="#plus-curator--sprite" />
              </svg>
            </div>
          </CommonFormItem>

          <CommonFormItem title="Преподаватели" hasHiding>
            <div class="curatorEducationPlan__formContent">
              <div
                class="curatorEducationPlan__list"
                v-for="(teacher, indx) in discipline.teachers"
                :key="`goal-${indx}`"
              >
                <CommonSelect
                  class="curatorEducationPlan__select"
                  :select-options="mappedTeachers"
                  v-model="teacher.fullname"
                  :value="teacher.fullname"
                  @onHideSelect="addTeacher(discipline.id, $event)"
                />
                <svg
                  class="curatorEducationPlan__bask"
                  @click="removeTeacher(discipline.id, teacher.id)"
                >
                  <use xlink:href="#bask-icon--sprite" />
                </svg>
              </div>

              <svg
                class="curatorEducationPlan__plus"
                @click="pushNewTeacher(i, index)"
              >
                <use xlink:href="#plus-curator--sprite" />
              </svg>
            </div>
          </CommonFormItem>

          <CommonFormItem title="Количество часов" hasHiding>
            <div class="curatorEducationPlan__formContent">
              <CommonInput
                v-model="discipline.hours_of_lectures"
                type="number"
                @change="updateSyllabus(discipline)"
              >
                Лекции
              </CommonInput>

              <CommonInput
                v-model="discipline.hours_of_practical"
                type="number"
                @change="updateSyllabus(discipline)"
              >
                Практические
              </CommonInput>

              <CommonInput
                v-model="discipline.hours_of_solo_study"
                type="number"
                @change="updateSyllabus(discipline)"
              >
                Самостоятельная работа
              </CommonInput>
            </div>
          </CommonFormItem>
        </div>
      </CommonFormItemSecond>
    </div>

    <svg
      class="curatorEducationPlan__plus"
      @click="createSyllabusDiscipline(item)"
    >
      <use xlink:href="#plus-curator--sprite" />
    </svg>
  </CommonFormItem>

  <svg class="curatorEducationPlan__plus" @click="createSyllabusSemester">
    <use xlink:href="#plus-curator--sprite" />
  </svg>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, reactive } from "vue";
import { CommonFormItem } from "@/components/CommonFormItem";
import { CommonSelect } from "@/components/CommonSelect";
import { CommonInput } from "@/components/CommonInput";
import { CommonFormItemSecond } from "@/components/CommonFormItemSecond";
import { useRoute } from "vue-router";
import store from "@/store";

import "@/assets/img/icons/bask-icon.svg?sprite";

export default defineComponent({
  name: "CuratorEducationPlan",

  components: {
    CommonFormItem,
    CommonSelect,
    CommonInput,
    CommonFormItemSecond,
  },

  setup() {
    const route = useRoute();

    const formModel = reactive({
      pickedDiscipline: "",
      lectures: [],
    });

    const degreeId = computed(() => route?.query?.degree_id);

    const programId = computed(() => route.query?.program_id);

    const syllabusList = computed(() =>
      store.getters.programsStore.syllabusList?.sort(
        (item: any, itemNext: any) =>
          item?.semester?.id - itemNext?.semester?.id
      )
    );
    const lecturesList = computed(
      () => store.getters.programsStore.lecturesList
    );
    const quizzesList = computed(() => store.getters.programsStore.quizzesList);
    const teachersList = computed(() => store.getters.userStore.usersList);
    const disciplinesList = computed(
      () => store.getters.programsStore.disciplinesList
    );

    const mappedLectures = computed(() =>
      lecturesList.value?.map((item: any) => {
        return {
          value: item.id,
          label: item.title,
        };
      })
    );
    const mappedQuizzes = computed(() =>
      quizzesList.value?.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
        };
      })
    );
    const mappedTeachers = computed(() =>
      teachersList.value?.map((item: any) => {
        return {
          value: item.id,
          label: item.fullname,
        };
      })
    );
    const mappedDisciplines = computed(() =>
      disciplinesList.value?.map((item: any) => {
        return {
          value: item.id,
          label: item.title,
        };
      })
    );

    const groupedSyllabus = computed(() => {
      const list: any = [];
      let secondList: any = [];
      let id = 1;

      syllabusList.value?.forEach((item: any) => {
        if (item?.semester?.id === id) {
          secondList.push(item);
        } else {
          id = item?.semester?.id;
          list.push(secondList);
          secondList = [];
          secondList.push(item);
        }
      });

      if (secondList?.length) {
        list.push(secondList);
      }

      return list;
    });

    const semesterTitle = (item: any) =>
      computed(
        () =>
          `${item?.semester?.study_year} год ${item?.semester?.study_part} семестр`
      );

    const pushNewLecture = (groupId: number, disciplineId: number) => {
      groupedSyllabus.value[groupId][disciplineId]?.lectures?.push({
        title: "",
      });
    };
    const pushNewQuizze = (groupId: number, disciplineId: number) => {
      groupedSyllabus.value[groupId][disciplineId]?.tests?.push({
        name: "",
      });
    };
    const pushNewTeacher = (groupId: number, disciplineId: number) => {
      groupedSyllabus.value[groupId][disciplineId]?.teachers?.push({
        fullname: "",
      });
    };

    const addLecture = async (syllabus_id: number, lecture_id: number) => {
      await store.dispatch.programsStore.addLecture({
        syllabus_id,
        lectures_ids: [lecture_id],
      });
      await store.dispatch.programsStore.getSyllabus(programId.value);
    };
    const removeLecture = async (syllabus_id: number, lecture_id: number) => {
      await store.dispatch.programsStore.removeLecture({
        syllabus_id,
        lectures_ids: [lecture_id],
      });
      await store.dispatch.programsStore.getSyllabus(programId.value);
    };

    const addQuizze = async (syllabus_id: number, quizze_id: number) => {
      await store.dispatch.programsStore.addQuizze({
        syllabus_id,
        tests_ids: [quizze_id],
      });
      await store.dispatch.programsStore.getSyllabus(programId.value);
    };
    const removeQuizze = async (syllabus_id: number, quizze_id: number) => {
      await store.dispatch.programsStore.removeQuizze({
        syllabus_id,
        tests_ids: [quizze_id],
      });
      await store.dispatch.programsStore.getSyllabus(programId.value);
    };

    const addTeacher = async (syllabus_id: number, teacher_id: number) => {
      await store.dispatch.programsStore.addTeacher({
        syllabus_id,
        teachers_ids: [teacher_id],
      });
      await store.dispatch.programsStore.getSyllabus(programId.value);
    };
    const removeTeacher = async (syllabus_id: number, teacher_id: number) => {
      await store.dispatch.programsStore.removeTeacher({
        syllabus_id,
        teachers_ids: [teacher_id],
      });
      await store.dispatch.programsStore.getSyllabus(programId.value);
    };

    const removeItem = (list: any, index: number) => {
      if (index === 0) {
        list.shift();
      } else {
        list.splice(index, index);
      }
    };

    const createSyllabus = async (
      discipline_id: number,
      semester_id: number
    ) => {
      await store.dispatch.programsStore.createSyllabus({
        program_id: programId.value,
        discipline_id,
        hours: 0,
        hours_of_lectures: 0,
        hours_of_practical: 0,
        hours_of_solo_study: 0,
        semester_id,
      });

      await store.dispatch.programsStore.getSyllabus(programId.value);

      formModel.pickedDiscipline = "";
    };

    const createSyllabusSemester = () => {
      // await store.dispatch.programsStore.createSyllabus({
      //   program_id: 1,
      //   semester_id: syllabusList.value?.length + isNewSemester ? 1 : 0,
      // });
      const prevSemester =
        syllabusList.value[syllabusList.value?.length - 1]?.semester;

      syllabusList.value.push({
        discipline: {
          title: formModel.pickedDiscipline,
        },
        semester: {
          id: prevSemester?.id + 1 || 1,
          study_part: prevSemester?.study_part === 1 ? 2 : 1,
          study_year:
            prevSemester?.study_part === 2
              ? Number(prevSemester?.study_year) + 1
              : Number(prevSemester?.study_year) || 1,
        },
      });
    };

    const createSyllabusDiscipline = (item: any) => {
      syllabusList.value?.push({
        discipline: {
          title: formModel.pickedDiscipline,
        },
        semester: {
          id: item[0]?.semester?.id,
          study_part: item[0]?.semester?.study_part,
          study_year: item[0]?.semester?.study_year,
        },
      });
    };

    const updateSyllabus = async (discipline: any) => {
      await store.dispatch.programsStore.updateSyllabus({
        syllabus_id: discipline.id,
        data: {
          hours: +discipline.hours,
          hours_of_lectures: +discipline.hours_of_lectures,
          hours_of_practical: +discipline.hours_of_practical,
          hours_of_solo_study: +discipline.hours_of_solo_study,
        },
      });

      await store.dispatch.programsStore.getSyllabus(programId.value);
    };
    const removeSyllabus = async (discipline: any) => {
      await store.dispatch.programsStore.removeSyllabus({
        syllabus_id: discipline.id,
      });

      await store.dispatch.programsStore.getSyllabus(programId.value);
    };

    onMounted(async () => {
      await store.dispatch.programsStore.getSyllabus(programId.value);
      await store.dispatch.programsStore.getLecture({
        degree_id: Number(degreeId.value) || 3,
      });
      await store.dispatch.programsStore.getQuizzes({
        degree_id: Number(degreeId.value) || 3,
      });
      await store.dispatch.userStore.getUsers({ filter: "teachers" });
    });

    return {
      mappedLectures,
      mappedQuizzes,
      mappedTeachers,
      mappedDisciplines,
      formModel,
      syllabusList,
      groupedSyllabus,
      addLecture,
      removeLecture,
      pushNewLecture,
      addQuizze,
      removeQuizze,
      pushNewQuizze,
      addTeacher,
      removeTeacher,
      pushNewTeacher,
      removeItem,
      semesterTitle,
      createSyllabus,
      createSyllabusSemester,
      createSyllabusDiscipline,
      updateSyllabus,
      removeSyllabus,
    };
  },
});
</script>

<style lang="stylus" scoped>
.curatorEducationPlan {
  &__formContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__disciplineList,
  &__formItemSec {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__plus {
    margin-top: 10px;
    width: 28px;
    height: 28px;
    &:hover {
      cursor: pointer;
      opacity: .6;
    }
  }

  &__list {
    display: flex;
    align-items: flex-end;
    gap: 15px;
  }

  &__select {
    flex-grow: 1;
  }

  &__bask {
    max-width: 40px;
    max-height: 40px;
    &:hover {
      cursor: pointer;
      opacity: .6;
    }
  }
}
</style>
