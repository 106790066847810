import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-923f7a26"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "curatorEducationHeader" }
const _hoisted_2 = {
  key: 0,
  class: "curatorEducationHeader__degreesBox"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
    (_ctx.hasShowDegreeBox)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lostDegrees, (item, i) => {
            return _withDirectives((_openBlock(), _createElementBlock("div", {
              key: item,
              class: "curatorEducationHeader__degreeItem",
              onClick: ($event: any) => (_ctx.goToDegree(i + 1), _ctx.$emit('toggleDegree', i + 1))
            }, _toDisplayString(item), 9, _hoisted_3)), [
              [_vShow, item !== undefined]
            ])
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}