<template>
  <LayoutBase @toggleTab="toggleDegree">
    <CuratorEducationHeader @toggleDegree="toggleDegree" />

    <CommonTabs
      :tabs-list="tabsList"
      :current-tab="currentTab?.value || 'programs'"
      @change-tab="changeTab"
    />

    <component class="content" :is="currentTab?.component" />
  </LayoutBase>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { prop } from "rambda";
import { tabsList } from "./constants";
import { CommonTabs } from "@/components/CommonTabs";
import { LayoutBase } from "@/layouts/LayoutBase";
import { CuratorEducationHeader } from "./components/CuratorEducationHeader";
import store from "@/store";

export default defineComponent({
  name: "CuratorContentPage",

  components: {
    CommonTabs,
    LayoutBase,
    CuratorEducationHeader,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const tab = computed(() => {
      const { tab = "" } = route.query;

      if (tabsList.map(prop("value")).includes(String(tab))) {
        return String(tab);
      }

      return "programs";
    });

    const currentTab = computed(() =>
      tabsList.find(({ value }) => value === tab.value)
    );

    const changeTab = (name: string) => {
      router.push({ path: route.path, query: { tab: name } });
    };

    const toggleDegree = (degree_id: number) => {
      store.dispatch.programsStore.getDegreeList({
        degree_id: degree_id || 3,
      });
    };

    return {
      tabsList,
      currentTab,
      toggleDegree,
      changeTab,
    };
  },
});
</script>

<style lang="stylus" scoped>
.content {
  margin-top: 20px;
}
</style>
