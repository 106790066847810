<template>
  <div class="curatorPrograms" v-if="!hasProgramConstructor">
    <h2 class="curatorPrograms__titleBox">Образовательные программы</h2>

    <div class="curatorPrograms__content">
      <svg class="curatorPrograms__plus" @click="goToNewProgram">
        <use xlink:href="#plus-curator--sprite" />
      </svg>

      <div
        class="curatorPrograms__programItem"
        v-for="program in programsAfterSlice"
        :key="program?.title"
      >
        <h4 class="curatorPrograms__programName">{{ program?.title }}</h4>

        <div class="curatorPrograms__iconsBox">
          <svg
            class="curatorPrograms__icon"
            @click="goToUpdateProgram(program.id)"
          >
            <use xlink:href="#edit-curator--sprite" />
          </svg>

          <svg
            class="curatorPrograms__icon"
            @click="removeProgram(program?.id)"
          >
            <use xlink:href="#remove-curator--sprite" />
          </svg>
        </div>
      </div>

      <CommonPagination
        :pagesCount="pagesCount"
        :current-page="page"
        @changePage="changePage"
      />
    </div>
  </div>

  <CuratorNewProgram v-else />
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref } from "vue";
import store from "@/store";
import { CommonPagination } from "@/components/CommonPagination";
import { CuratorNewProgram } from "./components/CuratorNewProgram";
import { useRoute, useRouter } from "vue-router";

import "@/assets/img/icons/plus-curator.svg?sprite";
import "@/assets/img/icons/edit-curator.svg?sprite";
import "@/assets/img/icons/remove-curator.svg?sprite";

export default defineComponent({
  name: "CuratorProgramsPage",

  components: {
    CommonPagination,
    CuratorNewProgram,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const degreeList = computed(() => store.getters.programsStore?.listData);

    const degreeId = computed(() => route?.query?.degree_id);

    const page = ref<number>(1);

    const pagesCount = computed(() =>
      Math.ceil((Number(degreeList.value?.length) || 0) / 8)
    );

    const homeworkForSingle = computed(() =>
      degreeList.value?.find((item: any) => item.id === Number(route.query.id))
    );

    const programsAfterSlice = computed(
      () =>
        degreeList.value?.slice(
          (page.value - 1) * 8,
          (page.value - 1) * 8 + 8
        ) || []
    );

    const hasProgramConstructor = computed(() =>
      Boolean(Number(route.query?.program_constructor))
    );

    const changePage = (item: number) => {
      page.value = item;
    };

    const removeProgram = async (programId: number) => {
      store.dispatch.programsStore.removeProgram({ program_id: programId });
      setTimeout(() => {
        store.dispatch.programsStore.getDegreeList({
          degree_id: Number(degreeId.value) || 3,
        });
      }, 1000);
    };

    const goToNewProgram = () =>
      router.push({ query: { ...route.query, program_constructor: 1, id: 1 } });

    const goToUpdateProgram = (program_id: number) =>
      router.push({
        query: { ...route.query, program_constructor: 1, id: 1, program_id },
      });

    onMounted(async () => {
      store.dispatch.programsStore.getDegreeList({
        degree_id: Number(degreeId.value) || 3,
      });
    });

    return {
      page,
      pagesCount,
      programsAfterSlice,
      hasProgramConstructor,
      goToUpdateProgram,
      changePage,
      removeProgram,
      goToNewProgram,
    };
  },
});
</script>

<style lang="stylus" scoped>
.curatorPrograms {
  &__titleBox {
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
    background-color: $colorLightGrey;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 20px;
    border: 1px solid $colorLightGrey;
    border-radius: 10px;
  }

  &__plus {
    width: 28px;
    height: 28px;
    &:hover {
      cursor: pointer;
      opacity: .6;
    }
  }

  &__programItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    background-color: $colorLightGrey;
  }

  &__iconsBox {
    display: flex;
    gap: 10px;
  }

  &__icon {
    width: 50px;
    height: 50px;
    &:hover {
      cursor: pointer;
      opacity: .6;
    }
  }

  &__programName {
    font-weight: normal;
  }
}
</style>
