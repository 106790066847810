import {
  State,
  FetchOfficeState,
  getFetchState,
  getFetchMutations,
  basicFetch,
} from "@/store/storeUtils";
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */

import { path, pathOr } from "rambda";

import { defineModule } from "direct-vuex";
import { modActionCtx, modGetterCtx } from "@/store/index";
import { api } from "@/utils/apiInstance";

import { Statuses } from "@/constants/statuses";
import { router } from "@/router";

const userStore = defineModule({
  namespaced: true,
  state: {
    ...getFetchState(),
    usersList: [],
    me: [],
  },

  getters: {
    usersList(...args): any {
      const { state } = getterCtx(args);

      const result = path("data.data", state.usersList);

      return result;
    },
    me(...args): any {
      const { state } = getterCtx(args);

      const result = path("data.data", state.me);

      return result;
    },
  },
  mutations: {
    ...getFetchMutations(),
    SET_INIT(state) {
      state.fetchState = Statuses.INIT;
    },
    SET_USERS_LIST(state, resp) {
      state.usersList = resp;
    },
    SET_ME(state, resp) {
      state.me = resp;
    },
  },
  actions: {
    async register(
      ctx,
      payload: {
        name: string;
        surname: string;
        patronymic: string;
        email: string;
        password: string;
        password_confirmation: string;
      }
    ) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      await basicFetch({
        method: api.sendPost,
        props: {
          url:
            window.location.hostname === "localhost"
              ? "/api/register"
              : "/backend/api/register",
          data: {
            ...payload,
          },
        },
        setState: commit.SET_STATE,
        callback: async (fetchState) => {
          if (fetchState === Statuses.REJECTED) {
            console.error("err");
          }

          if (fetchState === Statuses.FULFILLED) {
            commit.SET_INIT();
            console.log(state.response);

            router.replace("/");
          }
        },
      });
    },

    async login(
      ctx,
      payload: {
        email: string;
        password: string;
      }
    ) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      await basicFetch({
        method: api.sendPost,
        props: {
          url:
            window.location.hostname === "localhost"
              ? "/api/login"
              : "/backend/api/login",
          data: {
            ...payload,
          },
        },
        setState: commit.SET_STATE,
        callback: async (fetchState) => {
          if (fetchState === Statuses.REJECTED) {
            console.error("err");
          }

          if (fetchState === Statuses.FULFILLED) {
            commit.SET_INIT();
            console.log(state.response);

            router.replace("/");
          }
        },
      });
    },

    async getUsers(
      ctx,
      payload?: {
        filter?: string;
        withRoles?: boolean;
      }
    ) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      await basicFetch({
        method: () =>
          api.sendByGet(
            window.location.hostname === "localhost"
              ? `/api/users/${
                  payload?.filter ? "?filter=" + payload?.filter : ""
                }${payload?.withRoles ? "?include=roles" : ""}`
              : `/backend/api/users/${
                  payload?.filter ? "?filter=" + payload?.filter : ""
                }${payload?.withRoles ? "?include=roles" : ""}`
          ),
        setState: ({ fetchState }) => {
          console.log(fetchState);
        },
        callback: (status, resp) => {
          commit.SET_USERS_LIST(resp);
        },
      });
    },

    async getMe(ctx, payload?: string) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      await basicFetch({
        method: () =>
          api.sendByGet(
            window.location.hostname === "localhost"
              ? `/api/me/${payload ? payload : ""}`
              : `/backend/api/me/${payload ? payload : ""}`
          ),
        setState: ({ fetchState }) => {
          console.log(fetchState);
        },
        callback: (status, resp) => {
          commit.SET_ME(resp);
        },
      });
    },
  },
});

export default userStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, userStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, userStore);
