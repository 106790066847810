<template>
  <LayoutAuth :modalName="'registrationModal'" title="Регистрация">
    <template #title>Регистрация</template>

    <template #content>
      <CommonInput>Фамилия</CommonInput>

      <CommonInput>Имя</CommonInput>

      <CommonInput>Отчество</CommonInput>

      <CommonInput>Электронная почта</CommonInput>

      <CommonInput type="password">Пароль</CommonInput>

      <CommonInput type="password">Повторите пароль</CommonInput>

      <CommonButtonPrimary @click="register">
        Зарегистрироваться
      </CommonButtonPrimary>

      <p class="registration__btnInfo">
        Нажимая на кнопку, я соглашаюсь с
        <a href="#">Политикой обработки персональных данных</a>
      </p>

      <p class="registration__signInInfo">
        Уже есть аккаунт?
        <button
          class="registration__signInBtn link-style"
          @click="openSignInModal"
        >
          Войдите
        </button>
      </p>
    </template>
  </LayoutAuth>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { basicFetch } from "@/store/storeUtils";
import { api } from "@/utils/apiInstance";
import store from "@/store";
import { useVfm } from "vue-final-modal";
import { LayoutAuth } from "@/layouts/LayoutAuth/index";
import { CommonInput } from "@/components/CommonInput/index";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary/index";

export default defineComponent({
  name: "ModalRegistration",
  components: {
    LayoutAuth,
    CommonInput,
    CommonButtonPrimary,
  },

  async mounted() {
    // await basicFetch({
    //   method: () => api.sendByGet("/api/csrf-cookie"),
    //   setState: ({ fetchState }) => {
    //     console.log(fetchState);
    //   },
    //   callback: (state, resp) => {
    //     console.log(123);
    //     console.log(state, resp.cookies);
    //   },
    // });
  },

  setup() {
    const vfm = useVfm();

    const register = async () => {
      await store.dispatch.userStore.register({
        name: "test",
        surname: "test",
        patronymic: "test",
        email: "test@test.test",
        password: "Qwe123Qwe=",
        password_confirmation: "Qwe123Qwe=",
      });
    };

    const openSignInModal = () => {
      vfm.close("registrationModal");
      vfm.open("signInModal");
    };

    return {
      register,
      openSignInModal,
    };
  },
});
</script>

<style lang="stylus" scoped>
.registration {
  &__signInInfo {
    display: flex;
    justify-content: center;
  }
}
</style>
