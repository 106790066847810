export const navList = [
  {
    id: 1,
    label: "Заполнение программы",
    pathName: "1",
    queryName: "id",
  },
  {
    id: 2,
    label: "Учебный план",
    pathName: "2",
    queryName: "id",
  },
];

export const learningPeriods = [
  {
    value: "1",
    label: "Часы",
  },
  {
    value: "2",
    label: "Недели",
  },
  {
    value: "3",
    label: "Годы",
  },
];
