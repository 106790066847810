<template>
  <div class="curatorEducationHeader">
    <h2>
      {{ title }}
    </h2>

    <div class="curatorEducationHeader__degreesBox" v-if="hasShowDegreeBox">
      <div
        v-show="item !== undefined"
        v-for="(item, i) in lostDegrees"
        :key="item"
        class="curatorEducationHeader__degreeItem"
        @click="goToDegree(i + 1), $emit('toggleDegree', i + 1)"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { degrees } from "./constants";

export default defineComponent({
  name: "CuratorEducationHeader",

  emits: ["toggleDegree"],

  setup() {
    const route = useRoute();
    const router = useRouter();

    const hasShowDegreeBox = computed(() => {
      return route?.query?.degree_id && route?.query?.degree_id !== "4";
    });

    const title = computed(() => {
      if (route?.query?.degree_id) {
        return degrees[Number(route?.query?.degree_id) - 1];
      }
      return "Уровни образования";
    });

    const lostDegrees = computed(() => {
      return degrees.map((item: any, i: number) => {
        if (Number(route?.query?.degree_id) === i + 1 || i === 3) {
          return;
        }
        return item;
      });
    });

    const goToDegree = (degree_id: number) => {
      router.push({ query: { ...route?.query, degree_id } });
    };

    return {
      title,
      lostDegrees,
      hasShowDegreeBox,
      goToDegree,
    };
  },
});
</script>

<style lang="stylus" scoped>
.curatorEducationHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  &__degreesBox {
    display: flex;
    gap: 15px;
  }

  &__degreeItem {
    padding: 15px;
    border-radius: 10px;
    background-color: $colorLightGrey;

    &:hover {
      cursor: pointer;
      opacity: .6;
    }
  }
}
</style>
