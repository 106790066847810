<template>
  <header class="header">
    <div class="header__logoContainer">
      <svg class="header__logo">
        <use xlink:href="#logo-icon--sprite" />
      </svg>
    </div>

    <div class="header__content">
      <div class="header__iconsContainer">
        <router-link :to="{ name: RouteName.CHATS }">
          <svg class="header__icon">
            <use xlink:href="#message-icon--sprite" />
          </svg>
        </router-link>

        <VDropdown
          :distance="16"
          :shown="isShownNotifications"
          @apply-show="isShownNotifications = true"
          @apply-hide="isShownNotifications = false"
        >
          <svg class="header__icon">
            <use xlink:href="#bell-icon--sprite" />
          </svg>

          <template #popper>
            <div class="commonSelect__selectContainer">
              <CommonNotifications />
            </div>
          </template>
        </VDropdown>
      </div>

      <div class="header__personContainer">
        <CommonButtonPrimary @click="openSignInModal" v-if="!userFullName">
          Войти
        </CommonButtonPrimary>

        <div class="header__person bold-text" v-else>{{ userFullName }}</div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";
import { CommonNotifications } from "@/components/CommonNotifications";
import { useVfm } from "vue-final-modal";
import { RouteName } from "@/constants";
import store from "@/store";

import "@/assets/img/icons/logo-icon.svg?sprite";
import "@/assets/img/icons/message-icon.svg?sprite";
import "@/assets/img/icons/bell-icon.svg?sprite";

export default defineComponent({
  name: "LayoutHeader",
  components: {
    CommonButtonPrimary,
    CommonNotifications,
  },

  setup() {
    const vfm = useVfm();

    const me = computed(() => store.getters.userStore.me);

    const userFullName = computed(() => me.value?.fullname);

    const isShownNotifications = ref(false);

    const openSignInModal = () => vfm.open("signInModal");

    onMounted(() => {
      store.dispatch.userStore.getMe();
    });

    return {
      userFullName,
      RouteName,
      isShownNotifications,
      openSignInModal,
    };
  },
});
</script>

<style lang="stylus" scoped>
.header {
  width: 100%;
  position: sticky;
  z-index: 3;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 20px 15px;
  background-color: $colorFiller;
  border-bottom: 3px solid $colorMediumGrey;

  &__logo {
    width: 130px;
    height: 60px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1vw;
  }

  &__iconsContainer {
    display: flex;
    align-items: center;
    gap: .6vw;
  }

  &__icon {
    width: 20px;
    height: 20px;

    &:hover {
      opacity: .6;
      cursor: pointer;
    }
  }

  +mediaTablet() {
    padding: 2.1vw 13.6vw;

    &__logo {
      width: 15vw;
    }

    &__icon {
      width: 30px;
      height: 30px;
    }
  }
}
</style>
