import type { RouteRecordRaw } from "vue-router";

import { RouteName } from "@/constants";
import { ProfilePage } from "@/pages/ProfilePage/index";
import { EducationPage } from "@/pages/EducationPage/index";
import { HomeworksPage } from "@/pages/HomeworksPage/index";
import { LessonPage } from "@/pages/LessonPage/index";
import { TestPage } from "@/pages/TestPage/index";
import { PortfolioPage } from "@/pages/MagistracyPage/components/PortfolioPage/index";
import { EducationMaterialsPage } from "@/pages/EducationMaterialsPage/index";
import { ChatsPage } from "@/pages/ChatsPage/index";
import { InformationPage } from "@/pages/InformationPage/index";
import { AdvertPage } from "@/pages/InformationPage/components/AdvertPage";
import { DocsPage } from "@/pages/InformationPage/components/DocsPage";
import { DpoPage } from "@/pages/DpoPage";
import { EducationDpoPage } from "@/pages/DpoPage/components/EducationDpoPage";
import { LessonDpoPage } from "@/pages/DpoPage/components/EducationDpoPage/components/LessonDpoPage";
import { LectionDpoPage } from "@/pages/DpoPage/components/EducationDpoPage/components/LectionDpoPage";
import { HomeworksDpoPage } from "@/pages/DpoPage/components/EducationDpoPage/components/HomeworksDpoPage";
import { RecommendedCoursesDpo } from "@/pages/DpoPage/components/RecommendedCoursesDpo";
import { HomePage } from "@/pages/HomePage";
import { HomePlanPage } from "@/pages/HomePlanPage";
import { HomeCertPage } from "@/pages/HomeCertPage";
import { HomeDocsPage } from "@/pages/HomeDocsPage";
import { HomeFaqPage } from "@/pages/HomeFaqPage";
import { HomeReviewsPage } from "@/pages/HomeReviewsPage";
import { HomeAdmissionPage } from "@/pages/HomeAdmissionPage";
import { HomeProgramsListPage } from "@/pages/HomeProgramsListPage";
import { HomePageMain } from "@/pages/HomePageMain";
import { HomeMagistracyPage } from "@/pages/HomeMagistracyPage";
import { HomeMagistracyAboutPage } from "@/pages/HomeMagistracyAboutPage";
import { HomeMagistracyDocumentsPage } from "@/pages/HomeMagistracyDocumentsPage";
import { HomeMagistracyContactsPage } from "@/pages/HomeMagistracyContactsPage";
import { HomeMagistracyEntrancePage } from "@/pages/HomeMagistracyEntrancePage";
import { HomeMagistracyProgramsPage } from "@/pages/HomeMagistracyProgramsPage";
import { CuratorPage } from "@/pages/CuratorPage";
import { CuratorContentPage } from "@/pages/CuratorContentPage";

export const routes: RouteRecordRaw[] = [
  {
    name: RouteName.HOME,
    path: "/",
    component: HomePageMain,
  },
  {
    name: RouteName.CURATOR,
    path: "/curator",
    component: CuratorPage,
    children: [
      {
        name: RouteName.CURATOR_CONTENT,
        path: "content",
        component: CuratorContentPage,
      },
    ],
  },
  {
    name: RouteName.HOME_ORDINAT,
    path: "/ordinat",
    component: HomePage,
  },
  {
    name: RouteName.HOME_ORDINAT_PLAN,
    path: "/ordinat/plan",
    component: HomePlanPage,
  },
  {
    name: RouteName.HOME_ORDINAT_CERT,
    path: "/ordinat/cert",
    component: HomeCertPage,
  },
  {
    name: RouteName.HOME_ORDINAT_DOCS,
    path: "/ordinat/docs",
    component: HomeDocsPage,
  },
  {
    name: RouteName.HOME_ORDINAT_FAQ,
    path: "/ordinat/faq",
    component: HomeFaqPage,
  },
  {
    name: RouteName.HOME_ORDINAT_ADMISSION,
    path: "/ordinat/admission",
    component: HomeAdmissionPage,
  },
  {
    name: RouteName.HOME_ORDINAT_REVIEWS,
    path: "/ordinat/reviews",
    component: HomeReviewsPage,
  },
  {
    name: RouteName.HOME_ASPIRANT,
    path: "/aspirant",
    component: HomePage,
  },
  {
    name: RouteName.HOME_ASPIRANT_PLAN,
    path: "/aspirant/plan",
    component: HomePlanPage,
  },
  {
    name: RouteName.HOME_ASPIRANT_CERT,
    path: "/aspirant/cert",
    component: HomeCertPage,
  },
  {
    name: RouteName.HOME_ASPIRANT_DOCS,
    path: "/aspirant/docs",
    component: HomeDocsPage,
  },
  {
    name: RouteName.HOME_ASPIRANT_FAQ,
    path: "/aspirant/faq",
    component: HomeFaqPage,
  },
  {
    name: RouteName.HOME_ASPIRANT_ADMISSION,
    path: "/aspirant/admission",
    component: HomeAdmissionPage,
  },
  {
    name: RouteName.HOME_ASPIRANT_REVIEWS,
    path: "/aspirant/reviews",
    component: HomeReviewsPage,
  },
  {
    name: RouteName.HOME_COURSES,
    path: "/courses",
    component: HomePage,
  },
  {
    name: RouteName.HOME_COURSES_PLAN,
    path: "/courses/plan",
    component: HomePlanPage,
  },
  {
    name: RouteName.HOME_COURSES_CERT,
    path: "/courses/cert",
    component: HomeCertPage,
  },
  {
    name: RouteName.HOME_COURSES_FAQ,
    path: "/courses/faq",
    component: HomeFaqPage,
  },
  {
    name: RouteName.HOME_COURSES_ADMISSION,
    path: "/courses/admission",
    component: HomeAdmissionPage,
  },
  {
    name: RouteName.HOME_COURSES_REVIEWS,
    path: "/courses/reviews",
    component: HomeReviewsPage,
  },
  {
    name: RouteName.HOME_PROGRAMS_LIST,
    path: "/programs",
    component: HomeProgramsListPage,
  },
  {
    name: RouteName.HOME_MAGISTRACY,
    path: "/magistracy",
    component: HomeMagistracyPage,
  },
  {
    name: RouteName.HOME_MAGISTRACY_ABOUT,
    path: "/magistracy/about",
    component: HomeMagistracyAboutPage,
  },
  {
    name: RouteName.HOME_MAGISTRACY_DOCUMENTS,
    path: "/magistracy/documents",
    component: HomeMagistracyDocumentsPage,
  },
  {
    name: RouteName.HOME_MAGISTRACY_CONTACTS,
    path: "/magistracy/contacts",
    component: HomeMagistracyContactsPage,
  },
  {
    name: RouteName.HOME_MAGISTRACY__ENTRANCE,
    path: "/magistracy/entrance",
    component: HomeMagistracyEntrancePage,
  },
  {
    name: RouteName.HOME_MAGISTRACY_PROGRAM,
    path: "/magistracy/program",
    component: HomeMagistracyProgramsPage,
  },
  {
    name: RouteName.PROFILE,
    path: "/profile",
    component: ProfilePage,
  },
  {
    name: RouteName.EDUCATION,
    path: "/education",
    component: EducationPage,
    children: [
      {
        name: RouteName.HOMEWORKS,
        path: "homeworks",
        component: HomeworksPage,
      },
      {
        name: RouteName.LESSON,
        path: "lesson",
        component: LessonPage,
      },
      {
        name: RouteName.TEST,
        path: "test",
        component: TestPage,
      },
    ],
  },
  {
    name: RouteName.PORTFOLIO,
    path: "/portfolio",
    component: PortfolioPage,
  },
  {
    name: RouteName.EDUCATION_MATERIALS,
    path: "/education-materials",
    component: EducationMaterialsPage,
  },
  {
    name: RouteName.CHATS,
    path: "/chats",
    component: ChatsPage,
  },
  {
    name: RouteName.INFORMATION,
    path: "/information",
    component: InformationPage,
    children: [
      {
        name: RouteName.ADVERT,
        path: "advert",
        component: AdvertPage,
      },
      {
        name: RouteName.DOCS,
        path: "docs",
        component: DocsPage,
      },
    ],
  },

  {
    name: RouteName.DPO,
    path: "/dpo",
    component: DpoPage,
    children: [
      {
        name: RouteName.PROFILE_DPO,
        path: "profile",
        component: ProfilePage,
      },
      {
        name: RouteName.EDUCATION_DPO,
        path: "education",
        component: EducationDpoPage,
        children: [
          {
            name: RouteName.LESSON_DPO,
            path: "lesson",
            component: LessonDpoPage,
          },
          {
            name: RouteName.LECTION_DPO,
            path: "lection",
            component: LectionDpoPage,
          },
          {
            name: RouteName.HOMEWORK_DPO,
            path: "homework",
            component: HomeworksDpoPage,
          },
          {
            name: RouteName.TEST_DPO,
            path: "test",
            component: TestPage,
          },
        ],
      },
      {
        name: RouteName.COURSES_DPO,
        path: "courses",
        component: RecommendedCoursesDpo,
      },
    ],
  },
];
