export enum RouteName {
  HOME = "HOME",

  HOME_ORDINAT = "HOME_ORDINAT",
  HOME_ORDINAT_PLAN = "HOME_ORDINAT_PLAN",
  HOME_ORDINAT_CERT = "HOME_ORDINAT_CERT",
  HOME_ORDINAT_DOCS = "HOME_ORDINAT_DOCS",
  HOME_ORDINAT_FAQ = "HOME_ORDINAT_FAQ",
  HOME_ORDINAT_ADMISSION = "HOME_ORDINAT_ADMISSION",
  HOME_ORDINAT_REVIEWS = "HOME_ORDINAT_REVIEWS",

  HOME_ASPIRANT = "HOME_ASPIRANT",
  HOME_ASPIRANT_PLAN = "HOME_ASPIRANT_PLAN",
  HOME_ASPIRANT_CERT = "HOME_ASPIRANT_CERT",
  HOME_ASPIRANT_DOCS = "HOME_ASPIRANT_DOCS",
  HOME_ASPIRANT_FAQ = "HOME_ASPIRANT_FAQ",
  HOME_ASPIRANT_ADMISSION = "HOME_ASPIRANT_ADMISSION",
  HOME_ASPIRANT_REVIEWS = "HOME_ASPIRANT_REVIEWS",

  HOME_COURSES = "HOME_COURSES",
  HOME_COURSES_PLAN = "HOME_COURSES_PLAN",
  HOME_COURSES_CERT = "HOME_COURSES_CERT",
  HOME_COURSES_FAQ = "HOME_COURSES_FAQ",
  HOME_COURSES_ADMISSION = "HOME_COURSES_ADMISSION",
  HOME_COURSES_REVIEWS = "HOME_COURSES_REVIEWS",

  HOME_PROGRAMS_LIST = "HOME_PROGRAMS_LIST",

  HOME_MAGISTRACY = "HOME_MAGISTRACY",
  HOME_MAGISTRACY_ABOUT = "HOME_MAGISTRACY_ABOUT",
  HOME_MAGISTRACY_CONTACTS = "HOME_MAGISTRACY_CONTACTS",
  HOME_MAGISTRACY_DOCUMENTS = "HOME_MAGISTRACY_DOCUMENTS",
  HOME_MAGISTRACY__ENTRANCE = "HOME_MAGISTRACY_ENTRANCE",
  HOME_MAGISTRACY_PROGRAM = "HOME_MAGISTRACY_PROGRAM",

  CURATOR = "CURATOR",
  CURATOR_CONTENT = "CURATOR_CONTENT",

  PROFILE = "PROFILE",

  EDUCATION = "EDUCATION",
  HOMEWORKS = "HOMEWORKS",
  LESSON = "LESSON",
  TEST = "TEST",

  PORTFOLIO = "PORTFOLIO",

  EDUCATION_MATERIALS = "EDUCATION_MATERIALS",

  CHATS = "CHATS",

  INFORMATION = "INFORMATION",
  ADVERT = "ADVERT",
  DOCS = "DOCS",

  DPO = "DPO",
  PROFILE_DPO = "PROFILE_DPO",
  EDUCATION_DPO = "EDUCATION_DPO",
  LESSON_DPO = "LESSON_DPO",
  LECTION_DPO = "LECTION_DPO",
  HOMEWORK_DPO = "HOMEWORK_DPO",
  TEST_DPO = "TEST_DPO",
  COURSES_DPO = "COURSES_DPO",
}
