import { CuratorProgramsPage } from "./components/CuratorProgramsPage";
import { CuratorUsersPage } from "./components/CuratorUsersPage";

export const tabsList = [
  { value: "programs", label: "Программы", component: CuratorProgramsPage },
  {
    value: "users",
    label: "Пользователи",
    disabled: true,
    component: CuratorUsersPage,
  },
  {
    value: "groups",
    label: "Группы",
    disabled: true,
    component: CuratorProgramsPage,
  },
  {
    value: "schedule",
    label: "Расписание",
    disabled: true,
    component: CuratorProgramsPage,
  },
  {
    value: "library",
    label: "Библиотека",
    disabled: true,
    component: CuratorProgramsPage,
  },
];
