<template>
  <div class="commonSelect">
    <VDropdown
      :distance="16"
      :autoSize="true"
      :shown="isShown"
      @apply-show="isShown = true"
      @apply-hide="applyHide"
    >
      <CommonInput
        :value="selectedLabel"
        :opacityText="selectedLabel === hiddenText && !isShown"
        readonly
        style="cursor: pointer"
        v-bind="$attrs"
      >
        <slot>
          {{ label }}
        </slot>

        <template #suffix>
          <svg class="commonSelect__arrow" v-if="isShown">
            <use xlink:href="#arrow-top-icon--sprite" />
          </svg>

          <svg class="commonSelect__arrow" v-else>
            <use xlink:href="#arrow-bottom-icon--sprite" />
          </svg>
        </template>
      </CommonInput>

      <template #popper v-if="!isMultiselect">
        <div class="commonSelect__selectContainer">
          <CommonInput
            v-model="searchText"
            placeholder="Поиск"
            class="commonSelect__search"
          />

          <button
            :class="[
              'commonSelect__selectBtn',
              {
                commonSelect__selectBtn_selected: item.label === selectedLabel,
              },
            ]"
            v-close-popper
            v-for="item in filteredSelectOptions"
            :key="item.value"
            @click="setSelectedLabel(item.label, item.value)"
          >
            {{ item.label }}
          </button>
        </div>
      </template>

      <template #popper v-else>
        <div class="commonSelect__selectContainer">
          <CommonInput
            placeholder="Поиск"
            class="commonSelect__search"
            v-model="searchText"
          />

          <label
            :for="`select-checkbox-${i}`"
            v-for="(item, i) in filteredSelectOptions"
            :key="`select-checkbox-${i}`"
          >
            <input
              type="checkbox"
              :id="`select-checkbox-${i}`"
              v-model="item.isChecked"
            />

            {{ item.label }}
          </label>
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, type PropType } from "vue";
import type { CommonSelectProps } from "./types";
import { CommonInput } from "@/components/CommonInput";

import "@/assets/img/icons/arrow-top-icon.svg?sprite";
import "@/assets/img/icons/arrow-bottom-icon.svg?sprite";

export default defineComponent({
  name: "CommonSelect",

  components: {
    CommonInput,
  },

  emits: ["onHideSelect"],

  props: {
    selectOptions: {
      type: Array as PropType<CommonSelectProps["selectOptions"]>,
      default: () => [],
      required: true,
    },
    hiddenText: {
      type: String as PropType<CommonSelectProps["hiddenText"]>,
      default: "",
    },
    label: {
      type: String as PropType<CommonSelectProps["label"]>,
      default: "",
    },
    value: {
      type: String as PropType<CommonSelectProps["value"]>,
      default: "",
    },
    isMultiselect: {
      type: Boolean as PropType<CommonSelectProps["isMultiselect"]>,
      default: false,
      required: false,
    },
  },

  setup(props, { emit }) {
    const isShown = ref<boolean>(false);
    const searchText = ref<string>("");

    const selectedLabel = ref<string>(
      (!props.isMultiselect && props.value) || props.hiddenText
    );

    const selectedValue = ref<number | string>(0);

    const filteredSelectOptions = computed(() =>
      props.selectOptions?.filter((item: any) =>
        item.label.includes(searchText.value)
      )
    );

    const setSelectedLabel = (label: string, value: number | string) => {
      if (!props.isMultiselect) {
        selectedLabel.value = label;
        selectedValue.value = value;
      }
    };

    const applyHide = () => {
      isShown.value = false;
      emit("onHideSelect", selectedValue.value);
    };

    return {
      selectedLabel,
      selectedValue,
      isShown,
      searchText,
      filteredSelectOptions,
      setSelectedLabel,
      applyHide,
    };
  },
});
</script>

<style lang="stylus">
.v-popper__arrow-container {
  display: none;
}
</style>

<style lang="stylus" scoped>
.commonSelect {
  position: relative;
  &__arrow {
    width: 24px;
    height: 24px;
  }

  &__selectContainer {
    padding: 8px;
    padding-right: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__selectBtn {
    padding: 10px 16px;
    background-color: $colorFiller;
    border: none;
    border-radius: 5px;
    text-align: left;
    &_selected,
    &:hover {
      background-color: $colorSelectGrey;
    }
  }

  &__search {
    margin-bottom: 10px;
  }
}

.input.withSuffix {
  padding-right: 40px;
}
</style>
