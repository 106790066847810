<template>
  <LayoutHomepage>
    <CommonIntro />

    <div class="programsList">
      <div
        class="programsList__item"
        v-for="program in degreeList"
        :key="program.title"
      >
        <div class="programsList__imgBox">
          <img class="programsList__img" :src="program?.media?.banner1?.url" />

          <div v-if="hasCourses" class="programsList__imgContent">
            <div class="programsList__nmo">Баллы НМО</div>
          </div>
        </div>

        <h6 class="programsList__title">{{ program.title }}</h6>

        <div class="programsList__tabsList">
          <div class="programsList__listItem">
            {{ learningTime(program?.learning_time) }}
          </div>
          <div class="programsList__listItem">
            {{ program?.form }}
          </div>
        </div>

        <div class="form-submit">
          <button
            class="btn btn-primary"
            type="submit"
            @click="goToProgram(program.id)"
          >
            Подробнее
          </button>
        </div>
      </div>
    </div>

    <CommonApplication />
  </LayoutHomepage>
</template>

<script lang="ts">
import { defineComponent, onMounted, computed } from "vue";
import { LayoutHomepage } from "@/layouts/LayoutHomepage";
import { CommonApplication } from "./components/CommonApplication";
import { CommonIntro } from "./components/CommonIntro";
import { homeNavList } from "./constants";
import { useRoute, useRouter } from "vue-router";
import { RouteName } from "@/constants";
import store from "@/store";

import "@/assets/img/icons/teacher-icon-1.svg?sprite";
import "@/assets/img/icons/teacher-icon-2.svg?sprite";
import "@/assets/img/icons/teacher-icon-3.svg?sprite";

export default defineComponent({
  name: "HomeProgramsListPage",

  components: {
    LayoutHomepage,
    CommonApplication,
    CommonIntro,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const hasOrdinat = computed(() => Number(route?.query?.id) === 1);
    const hasAspirant = computed(() => Number(route?.query?.id) === 2);
    const hasCourses = computed(
      () => Number(route?.query?.id) === 4 || !route?.query?.id
    );

    const degreeList = computed(() => store.getters.programsStore?.listData);

    const bodyStyleName = computed(() => {
      if (hasOrdinat.value) {
        return "ordinat";
      } else if (hasAspirant.value) {
        return "aspirant";
      }
      return "courses";
    });

    const degreeId = computed(() => {
      if (hasOrdinat.value) {
        return 1;
      } else if (hasAspirant.value) {
        return 2;
      }

      return 4;
    });

    const toggleBodyStyle = () => {
      document.body.classList.add(bodyStyleName.value);
    };

    const goToProgram = (id: number) => {
      const scrollToTop = () => window.scrollTo(0, 0);

      if (hasAspirant.value) {
        router.push({ name: RouteName.HOME_ASPIRANT, query: { id } });
        toggleBodyStyle();
        scrollToTop();
      } else if (hasCourses.value) {
        router.push({ name: RouteName.HOME_COURSES, query: { id } });
        toggleBodyStyle();
        scrollToTop();
      }
    };

    const learningTime = (obj: any) => {
      if (obj?.hours) {
        return `${obj?.hours} ак.ч.`;
      } else if (obj?.days) {
        return `${obj?.days} д.`;
      }

      return `${obj?.years} г.`;
    };

    onMounted(async () => {
      toggleBodyStyle();

      store.dispatch.programsStore.getDegreeList({ degree_id: degreeId.value });
    });

    return {
      hasCourses,
      route,
      homeNavList,
      degreeList,
      toggleBodyStyle,
      goToProgram,
      learningTime,
    };
  },
});
</script>

<style lang="stylus" scoped>
.header {
  &__icon {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }
}

.programsList {
  margin: 0 auto;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(1, 80%);
  gap: 40px;

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
  }

  &__imgBox {
    width: 100%;
    height: 272px;
    position: relative;
    border-radius: 24px;
    overflow: hidden;
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: $colorLightGrey;
  }

  &__tabsList {
    display: flex;
    gap: 5px;
  }

  &__listItem {
    padding: 10px;
    border: 1px solid #E24E25;
    border-radius: 30px;
  }
  &__imgContent {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
  }

  &__nmo {
    padding: 10px;
    background-color: $colorFiller;
    border-radius: 30px;
    color: #E24E25;
  }

  +mediaTablet() {
    grid-template-columns: repeat(3, calc(32% - 20px));
  }
  @media (min-width:1400px) {
    max-width: 1400px;
  }
}
</style>
