import {
  getFetchState,
  getFetchMutations,
  basicFetch,
} from "@/store/storeUtils";
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */

import { defineModule } from "direct-vuex";
import { modActionCtx, modGetterCtx } from "@/store/index";
import { api } from "@/utils/apiInstance";
import { path } from "rambda";

import { Statuses } from "@/constants/statuses";

const programsStore = defineModule({
  namespaced: true,
  state: {
    ...getFetchState(),
    listData: [],
    disciplinesList: [],
    lecturesList: [],
    quizzesList: [],
    syllabusList: [],
    magistracy: [],
    createdStatus: null,
    pickedProgramId: null,
  },

  getters: {
    data(...args): any {
      const { state } = getterCtx(args);

      const result = path("data.data", state.response);

      return result;
    },
    listData(...args): any {
      const { state } = getterCtx(args);

      const result = path("data.data", state.listData);

      return result;
    },
    disciplinesList(...args): any {
      const { state } = getterCtx(args);

      const result = path("data.data", state.disciplinesList);

      return result;
    },
    lecturesList(...args): any {
      const { state } = getterCtx(args);

      const result = path("data.data", state.lecturesList);

      return result;
    },
    quizzesList(...args): any {
      const { state } = getterCtx(args);

      const result = path("data.data", state.quizzesList);

      return result;
    },
    syllabusList(...args): any {
      const { state } = getterCtx(args);

      const result = path("data.data", state.syllabusList);

      return result;
    },
    magistracy(...args): any {
      const { state } = getterCtx(args);

      const result = path("data.data", state.magistracy);

      return result;
    },
    // createdState(...args): any {
    //   const { state } = getterCtx(args);

    //   const result = path("data", state.createdStatus);

    //   return result;
    // },
  },
  mutations: {
    ...getFetchMutations(),
    SET_INIT(state) {
      state.fetchState = Statuses.INIT;
    },
    SET_DATA(state, resp) {
      state.response = resp;
    },
    SET_CREATED_STATUS(state, status) {
      state.createdStatus = status;
    },
    SET_LIST_DATA(state, resp) {
      state.listData = resp;
    },
    SET_DISCIPLINES_LIST(state, resp) {
      state.disciplinesList = resp;
    },
    SET_LECTURES_LIST(state, resp) {
      state.lecturesList = resp;
    },
    SET_QUIZZES_LIST(state, resp) {
      state.quizzesList = resp;
    },
    SET_SYLLABUS_LIST(state, resp) {
      state.syllabusList = resp;
    },
    SET_PICKED_PROGRAM(state, id) {
      state.pickedProgramId = id;
    },
    SET_MAGISTRACY(state, resp) {
      state.magistracy = resp;
    },
  },
  actions: {
    async getPrograms(
      ctx,
      payload: {
        program_id?: number;
        is_magistracy?: boolean;
      }
    ) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      if (payload?.program_id !== state.pickedProgramId) {
        if (!payload?.is_magistracy) {
          commit.SET_PICKED_PROGRAM(payload.program_id);
        }

        await basicFetch({
          method: () =>
            api.sendByGet(
              window.location.hostname === "localhost"
                ? `/api/programs/${
                    payload.program_id ? payload.program_id : ""
                  }${
                    payload?.is_magistracy
                      ? "?filter[degree_id]=3&filter[is_active]=true"
                      : ""
                  }`
                : `/backend/api/programs/${
                    payload.program_id ? payload.program_id : ""
                  }${
                    payload?.is_magistracy
                      ? "?filter[degree_id]=3&filter[is_active]=true"
                      : ""
                  }`
            ),
          setState: ({ fetchState }) => {
            console.log(fetchState);
          },
          callback: (status, resp) => {
            if (payload?.is_magistracy) {
              commit.SET_MAGISTRACY(resp);
            } else {
              commit.SET_DATA(resp);
            }
          },
        });
      }
    },

    async getMagistracy(
      ctx,
      payload: {
        program_id?: number;
      }
    ) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      if (payload.program_id !== state.pickedProgramId) {
        commit.SET_PICKED_PROGRAM(payload.program_id);

        await basicFetch({
          method: () =>
            api.sendByGet(
              window.location.hostname === "localhost"
                ? `/api/programs/${payload.program_id}`
                : `/backend/api/programs/${payload.program_id}`
            ),
          setState: ({ fetchState }) => {
            console.log(fetchState);
          },
          callback: (status, resp) => {
            commit.SET_DATA(resp);
          },
        });
      }
    },

    async getDegreeList(
      ctx,
      payload: {
        degree_id?: number;
      }
    ) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      await basicFetch({
        method: () =>
          api.sendByGet(
            window.location.hostname === "localhost"
              ? `/api/programs?filter[degree_id]=${payload.degree_id}`
              : `/backend/api/programs?filter[degree_id]=${payload.degree_id}`
          ),
        setState: ({ fetchState }) => {
          console.log(fetchState);
        },
        callback: (status, resp) => {
          commit.SET_LIST_DATA(resp);
        },
      });
    },

    async removeProgram(
      ctx,
      payload: {
        program_id?: number;
      }
    ) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      await basicFetch({
        method: api.sendDelete,
        props: {
          url:
            window.location.hostname === "localhost"
              ? `/api/programs/${payload.program_id}`
              : `/backend/api/programs/${payload.program_id}`,
        },
        setState: ({ fetchState }) => {
          console.log(fetchState);
        },
      });
    },

    async createProgram(
      ctx,
      payload: { data: any; isUpdate: any; program_id?: number }
    ) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      await basicFetch({
        method: api.sendFormDataPost(
          window.location.hostname === "localhost"
            ? `/api/programs${
                payload.isUpdate
                  ? "/" + payload.program_id + "?_method=PUT"
                  : ""
              }`
            : `/backend/api/programs${
                payload.isUpdate
                  ? "/" + payload.program_id + "?_method=PUT"
                  : ""
              }`,
          payload.data
        ),
        setState: ({ fetchState }) => {
          console.log(fetchState);
        },
        callback: (status, resp) => {
          commit.SET_CREATED_STATUS(status);
        },
      });
    },

    async getDisciplines(
      ctx,
      payload: {
        degree_id?: number;
      }
    ) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      await basicFetch({
        method: () =>
          api.sendByGet(
            window.location.hostname === "localhost"
              ? `/api/disciplines?filter[degree_id]=${payload.degree_id}`
              : `/backend/api/disciplines?filter[degree_id]=${payload.degree_id}`
          ),
        setState: ({ fetchState }) => {
          console.log(fetchState);
        },
        callback: (status, resp) => {
          commit.SET_DISCIPLINES_LIST(resp);
        },
      });
    },
    async getLecture(
      ctx,
      payload: {
        degree_id?: number;
      }
    ) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      await basicFetch({
        method: () =>
          api.sendByGet(
            window.location.hostname === "localhost"
              ? `/api/lectures/?filter=degree_id=${payload.degree_id}`
              : `/backend/api/lectures/?filter=degree_id=${payload.degree_id}`
          ),
        setState: ({ fetchState }) => {
          console.log(fetchState);
        },
        callback: (status, resp) => {
          commit.SET_LECTURES_LIST(resp);
        },
      });
    },

    async addLecture(ctx, payload: { syllabus_id: number; lectures_ids: any }) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      await basicFetch({
        method: api.sendPost,
        props: {
          url:
            window.location.hostname === "localhost"
              ? `/api/syllabuses/${payload.syllabus_id}/lectures`
              : `/backend/api/syllabuses/${payload.syllabus_id}/lectures`,
          data: {
            lectures_ids: payload.lectures_ids,
          },
        },
        setState: ({ fetchState }) => {
          console.log(fetchState);
        },
      });
    },

    async removeLecture(
      ctx,
      payload: { syllabus_id: number; lectures_ids: any }
    ) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      await basicFetch({
        method: api.sendDelete,
        props: {
          url:
            window.location.hostname === "localhost"
              ? `/api/syllabuses/${payload.syllabus_id}/lectures`
              : `/backend/api/syllabuses/${payload.syllabus_id}/lectures`,
          data: {
            lectures_ids: payload.lectures_ids,
          },
        },
        setState: ({ fetchState }) => {
          console.log(fetchState);
        },
      });
    },

    async getQuizzes(
      ctx,
      payload: {
        degree_id?: number;
      }
    ) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      await basicFetch({
        method: () =>
          api.sendByGet(
            window.location.hostname === "localhost"
              ? `/api/quizzes/?filter=degree_id=${payload.degree_id}`
              : `/backend/api/quizzes/?filter=degree_id=${payload.degree_id}`
          ),
        setState: ({ fetchState }) => {
          console.log(fetchState);
        },
        callback: (status, resp) => {
          commit.SET_QUIZZES_LIST(resp);
        },
      });
    },

    async addQuizze(ctx, payload: { syllabus_id: number; tests_ids: any }) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      await basicFetch({
        method: api.sendPost,
        props: {
          url:
            window.location.hostname === "localhost"
              ? `/api/syllabuses/${payload.syllabus_id}/tests`
              : `/backend/api/syllabuses/${payload.syllabus_id}/tests`,
          data: {
            tests_ids: payload.tests_ids,
          },
        },
        setState: ({ fetchState }) => {
          console.log(fetchState);
        },
      });
    },

    async removeQuizze(ctx, payload: { syllabus_id: number; tests_ids: any }) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      await basicFetch({
        method: api.sendDelete,
        props: {
          url:
            window.location.hostname === "localhost"
              ? `/api/syllabuses/${payload.syllabus_id}/tests`
              : `/backend/api/syllabuses/${payload.syllabus_id}/tests`,
          data: {
            tests_ids: payload.tests_ids,
          },
        },
        setState: ({ fetchState }) => {
          console.log(fetchState);
        },
      });
    },

    async addTeacher(ctx, payload: { syllabus_id: number; teachers_ids: any }) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      await basicFetch({
        method: api.sendPost,
        props: {
          url:
            window.location.hostname === "localhost"
              ? `/api/syllabuses/${payload.syllabus_id}/teachers`
              : `/backend/api/syllabuses/${payload.syllabus_id}/teachers`,
          data: {
            teachers_ids: payload.teachers_ids,
          },
        },
        setState: ({ fetchState }) => {
          console.log(fetchState);
        },
      });
    },

    async removeTeacher(
      ctx,
      payload: { syllabus_id: number; teachers_ids: any }
    ) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      await basicFetch({
        method: api.sendDelete,
        props: {
          url:
            window.location.hostname === "localhost"
              ? `/api/syllabuses/${payload.syllabus_id}/teachers`
              : `/backend/api/syllabuses/${payload.syllabus_id}/teachers`,
          data: {
            teachers_ids: payload.teachers_ids,
          },
        },
        setState: ({ fetchState }) => {
          console.log(fetchState);
        },
      });
    },

    async getSyllabus(ctx, program_id) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      await basicFetch({
        method: () =>
          api.sendByGet(
            window.location.hostname === "localhost"
              ? `/api/syllabuses?filter[program_id]=${program_id}&include=teachers,lectures,tests`
              : `/backend/api/syllabuses?filter[program_id]=${program_id}&include=teachers,lectures,tests`
          ),
        setState: ({ fetchState }) => {
          console.log(fetchState);
        },
        callback: (status, resp) => {
          commit.SET_SYLLABUS_LIST(resp);
        },
      });
    },

    async createSyllabus(ctx, payload) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      await basicFetch({
        method: api.sendPost,
        props: {
          url:
            window.location.hostname === "localhost"
              ? `/api/syllabuses`
              : `/backend/api/syllabuses`,
          data: payload,
        },
        setState: ({ fetchState }) => {
          console.log(fetchState);
        },
      });
    },

    async updateSyllabus(ctx, payload: { syllabus_id: number; data: any }) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      await basicFetch({
        method: api.sendPost,
        props: {
          url:
            window.location.hostname === "localhost"
              ? `/api/syllabuses/${payload.syllabus_id}?_method=PATCH`
              : `/backend/api/syllabuses/${payload.syllabus_id}?_method=PATCH`,
          data: payload.data,
        },
        setState: ({ fetchState }) => {
          console.log(fetchState);
        },
      });
    },
    async removeSyllabus(ctx, payload: { syllabus_id: number }) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === Statuses.PENDING) {
        return;
      }

      await basicFetch({
        method: api.sendDelete,
        props: {
          url:
            window.location.hostname === "localhost"
              ? `/api/syllabuses/${payload.syllabus_id}`
              : `/backend/api/syllabuses/${payload.syllabus_id}`,
        },
        setState: ({ fetchState }) => {
          console.log(fetchState);
        },
      });
    },
  },
});

export default programsStore;
const getterCtx = (args: [any, any, any, any]) =>
  modGetterCtx(args, programsStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, programsStore);
