import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1464e19d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "curator__programsList" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CuratorEducationHeader = _resolveComponent("CuratorEducationHeader")!
  const _component_LayoutBase = _resolveComponent("LayoutBase")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.hasRouterCurator)
    ? (_openBlock(), _createBlock(_component_LayoutBase, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_CuratorEducationHeader),
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.programs, (item, i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.title,
                class: _normalizeClass(['curator__program', `curator__program-${i}`]),
                onClick: ($event: any) => (_ctx.goToContent(item.degree_id))
              }, _toDisplayString(item.title), 11, _hoisted_2))
            }), 128))
          ])
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
}