<template>
  <h2 class="curatorUsers__titleBox">
    {{ usersTitle }}
  </h2>
  <div class="curatorUsers" v-if="!hasUserConstructor">
    <div class="curatorUsers__content">
      <svg class="curatorUsers__plus" @click="goToNewProgram">
        <use xlink:href="#plus-curator--sprite" />
      </svg>

      <div
        class="curatorUsers__userItem"
        v-for="user in usersAfterSlice"
        :key="user?.fullname"
      >
        <div class="curatorUsers__nameBox">
          <h4 class="curatorUsers__userName">{{ user?.fullname }}</h4>

          <p class="curatorUsers__userEmail">{{ user?.email }}</p>
        </div>

        <div class="curatorUsers__iconsBox">
          <div class="curatorUsers__rolesBox">
            <div
              class="curatorUsers__userRole"
              v-for="role in user?.roles"
              :key="role.id"
            >
              {{ roles[role.name] }}
            </div>
          </div>

          <svg class="curatorUsers__icon" @click="goToUpdateUser(user.id)">
            <use xlink:href="#edit-curator--sprite" />
          </svg>

          <svg class="curatorUsers__icon" @click="removeProgram(user?.id)">
            <use xlink:href="#remove-curator--sprite" />
          </svg>
        </div>
      </div>

      <CommonPagination
        :pagesCount="pagesCount"
        :current-page="page"
        @changePage="changePage"
      />
    </div>
  </div>

  <div v-else-if="!sectionId">
    <h4 class="curatorUsers__userName curatorUsers__titleBox">
      Профиль

      <svg class="curatorUsers__arrow" @click="goToUpdateSection(0)">
        <use xlink:href="#pagination-arrow-left-icon--sprite" />
      </svg>
    </h4>

    <h4 class="curatorUsers__userName curatorUsers__titleBox">
      Изменение ролей

      <svg class="curatorUsers__arrow" @click="goToUpdateSection(1)">
        <use xlink:href="#pagination-arrow-left-icon--sprite" />
      </svg>
    </h4>
  </div>

  <div v-else>
    <CuratorUserProfile />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref } from "vue";
import store from "@/store";
import { roles, sections } from "./constants";
import { CommonPagination } from "@/components/CommonPagination";
import { CuratorUserProfile } from "./components/CuratorUserProfile";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "CuratorUsersPage",

  components: {
    CommonPagination,
    CuratorUserProfile,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const page = ref<number>(1);

    const degreeId = computed(() => route?.query?.degree_id);

    const userId = computed(() => route.query.user_id);

    const sectionId = computed(() => route.query.section_id);

    const usersTitle = computed(() => {
      if (!hasUserConstructor.value) {
        return "Пользователи";
      } else if (!sectionId.value) {
        return usersList.value?.find(
          (item: any) => +item.id === Number(userId.value)
        )?.fullname;
      }
      return sections[+sectionId.value];
    });

    const usersList = computed(() => store.getters.userStore.usersList);

    const pagesCount = computed(() =>
      Math.ceil((Number(usersList.value?.length) || 0) / 8)
    );

    const homeworkForSingle = computed(() =>
      usersList.value?.find((item: any) => item.id === Number(route.query.id))
    );

    const usersAfterSlice = computed(
      () =>
        usersList.value?.slice(
          (page.value - 1) * 8,
          (page.value - 1) * 8 + 8
        ) || []
    );

    const hasUserConstructor = computed(() =>
      Boolean(Number(route.query?.user_constructor))
    );

    const changePage = (item: number) => {
      page.value = item;
    };

    const removeProgram = async (programId: number) => {
      store.dispatch.programsStore.removeProgram({ program_id: programId });
      setTimeout(() => {
        store.dispatch.programsStore.getDegreeList({
          degree_id: Number(degreeId.value) || 3,
        });
      }, 1000);
    };

    const goToNewProgram = () =>
      router.push({ query: { ...route.query, program_constructor: 1, id: 1 } });

    const goToUpdateUser = (user_id: number) =>
      router.push({
        query: { ...route.query, user_constructor: 1, id: 1, user_id },
      });

    const goToUpdateSection = (section_id: number) => {
      router.push({
        query: { ...route.query, section_id },
      });
    };

    onMounted(async () => {
      store.dispatch.userStore.getUsers({ withRoles: true });
    });

    return {
      page,
      pagesCount,
      usersAfterSlice,
      hasUserConstructor,
      roles,
      usersList,
      userId,
      usersTitle,
      sectionId,
      goToUpdateSection,
      goToUpdateUser,
      changePage,
      removeProgram,
      goToNewProgram,
    };
  },
});
</script>

<style lang="stylus" scoped>
.curatorUsers {
  &__titleBox {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
    background-color: $colorLightGrey;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 20px;
    border: 1px solid $colorLightGrey;
    border-radius: 10px;
  }

  &__plus {
    width: 28px;
    height: 28px;
    &:hover {
      cursor: pointer;
      opacity: .6;
    }
  }

  &__userItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    background-color: $colorLightGrey;
  }

  &__iconsBox {
    display: flex;
    gap: 10px;
  }

  &__icon {
    width: 50px;
    height: 50px;
    &:hover {
      cursor: pointer;
      opacity: .6;
    }
  }

  &__nameBox {
    display: flex;
    align-items: flex-end;
    gap: 20px;
  }

  &__userName {
    font-weight: normal;
  }

  &__userEmail {
    margin: 0;
    color: $colorDarkGrey;
  }

  &__rolesBox {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__userRole {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 16px;
    background-color: $colorFiller;
    border-radius: 10px;
  }

  &__titleBox {
    padding: 15px;
    border-radius: 10px;
    background-color: $colorLightGrey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__arrow {
    width: 32px;
    height: 32px;
    &:hover {
      cursor: pointer;
      opacity: .6;
    }
  }

  &__arrow {
    transform: rotate(180deg);
  }
}
</style>
