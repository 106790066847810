import { useToast } from "vue-toastification";
import { MessageResponseInterface } from "./api/apiTypes";

const toast = useToast();

export type IType = "error" | "success" | "info" | "warning";

const runNotice = (type: IType, message: string) => {
  const types: { [key: string]: IType } = {
    error: "error",
    success: "success",
    danger: "error",
    info: "info",
    warning: "warning",
  };

  toast[types[type] || types.info](message, {
    timeout: 3000,
    icon: true,
  });
};

export const notifyHandler = (
  messages?: MessageResponseInterface[] | MessageResponseInterface
): void => {
  if (!messages) {
    return;
  }

  if (Array.isArray(messages)) {
    messages.forEach((message) => {
      runNotice(message.theme, message.text);
    });
  }

  if (typeof messages === "object" && !Array.isArray(messages)) {
    if (messages.text === "NotAcceptable" || messages.text === "Unauthorized") {
      return;
    }

    runNotice(messages.theme, messages.text);
  }

  if (typeof messages === "string") {
    runNotice("error", messages);
  }
};

export default notifyHandler;
