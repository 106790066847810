<template>
  <div class="curatorUserProfile">
    <CommonFormItem title="Учетные данные">
      <div class="curatorUserProfile__firstBlock">
        <CommonInput>Фамилия</CommonInput>
        <CommonInput>Имя</CommonInput>
        <CommonInput>Отчество</CommonInput>
        <CommonInput>Дата рождения</CommonInput>
        <CommonInput>Электронная почта</CommonInput>
        <CommonInput>Телефон</CommonInput>
      </div>
    </CommonFormItem>

    <CommonFormItem title="Документы">
      <h4 class="curatorUserProfile__title">Паспорт</h4>

      <div class="curatorUserProfile__secondBlock">
        <CommonInput>Серия</CommonInput>
        <CommonInput>Номер</CommonInput>
        <CommonInput>Кем выдан</CommonInput>
        <CommonInput class="curatorUserProfile__date" type="date">
          Дата выдачи
        </CommonInput>
      </div>

      <h4 class="curatorUserProfile__title">СНИЛС и ИНН</h4>

      <div class="curatorUserProfile__thirdBlock">
        <CommonInput>СНИЛС</CommonInput>
        <CommonInput>ИНН</CommonInput>
      </div>

      <h4 class="curatorUserProfile__title">Прикрепленные файлы</h4>

      <div class="curatorUserProfile__thirdBlock">
        <CommonInput class="curatorUserProfile__file" />
        <CommonInput class="curatorUserProfile__file" />
      </div>
    </CommonFormItem>

    <CommonFormItem title="Образование">
      <div class="curatorUserProfile__thirdBlock">
        <CommonInput>Уровень образования</CommonInput>
        <CommonInput>Специальность</CommonInput>
      </div>
    </CommonFormItem>

    <CommonFormItem title="Повышение квалификации">
      <CommonInput>Наименование программы</CommonInput>

      <div>
        <CommonInput>Количество часов</CommonInput>
        <CommonInput>Год окончания</CommonInput>
      </div>
    </CommonFormItem>

    <CommonFormItem title="Повышение квалификации">
      <div class="curatorUserProfile__firstBlock">
        <CommonInput>Наименование программы</CommonInput>
        <CommonInput>Количество часов</CommonInput>
        <CommonInput>Отчество</CommonInput>
        <CommonInput>Дата рождения</CommonInput>
        <CommonInput>Электронная почта</CommonInput>
        <CommonInput>Телефон</CommonInput>
      </div>
    </CommonFormItem>

    <CommonFormItem title="Опыт работы">
      <CommonInput class="curatorUserProfile__work">Место работы</CommonInput>
      <CommonInput>Должность</CommonInput>
      <CommonInput>Начало работы</CommonInput>
      <CommonInput>Окончание работы</CommonInput>
    </CommonFormItem>

    <CommonFormItem title="Профессиональные интересы">
      <CommonInput>Фамилия</CommonInput>
    </CommonFormItem>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, reactive } from "vue";
import { RouteName } from "@/constants";
import { useRoute } from "vue-router";
import { CommonFormItem } from "@/components/CommonFormItem";
import { CommonInput } from "@/components/CommonInput";

export default defineComponent({
  name: "CuratorUserProfile",

  components: {
    CommonFormItem,
    CommonInput,
  },

  setup() {
    const formModel = reactive({
      name: "",
    });
    const route = useRoute();

    const hasRouterCurator = computed(() => route.name === RouteName.CURATOR);

    return {
      hasRouterCurator,
    };
  },
});
</script>

<style lang="stylus" scoped>
.curatorUserProfile {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__title {
    margin-top: 10px;
    font-weight: normal;
  }

  &__firstBlock,
  &__secondBlock {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }

  &__thirdBlock {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__date {
    max-width: 220px;
  }

  &__file {
    width: fit-content;
  }

  +mediaTablet() {
    &__firstBlock,
    &__secondBlock {
      grid-template-columns: repeat(2, 1fr);
    }

    &__thirdBlock {
      flex-direction: row;
    }
  }

  +mediaLarge() {
    &__firstBlock {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>
