<template>
  <LayoutBase v-if="hasRouterCurator">
    <CuratorEducationHeader />

    <div class="curator__programsList">
      <div
        v-for="(item, i) in programs"
        :key="item.title"
        :class="['curator__program', `curator__program-${i}`]"
        @click="goToContent(item.degree_id)"
      >
        {{ item.title }}
      </div>
    </div>
  </LayoutBase>

  <router-view v-else />
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, watchEffect } from "vue";
import { LayoutBase } from "@/layouts/LayoutBase";
import { RouteName } from "@/constants";
import { useRoute, useRouter } from "vue-router";
import { CuratorEducationHeader } from "../CuratorContentPage/components/CuratorEducationHeader";
import { programs } from "./constants";

export default defineComponent({
  name: "CuratorPage",

  components: {
    CuratorEducationHeader,
    LayoutBase,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const hasRouterCurator = computed(() => route.name === RouteName.CURATOR);

    const goToContent = (degree_id: number) => {
      router.push({
        name: RouteName.CURATOR_CONTENT,
        query: { ...route?.query, degree_id },
      });
    };

    onMounted(() => {
      if (!route?.query?.educationType) {
        router.push({
          query: { ...route.query, educationType: "higherEducation" },
        });
      }
    });

    watchEffect(() => {
      if (route?.query?.educationType === "dpo") {
        goToContent(4);
      }
    });

    return {
      hasRouterCurator,
      programs,
      goToContent,
    };
  },
});
</script>

<style lang="stylus" scoped>
.curator {
  &__programsList {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    gap: 10px;
  }

  &__program {
    flex-grow: 1;
    padding: 40px;
    color: $colorFiller;
    border-radius: 10px;
    text-align: center;
    &:hover {
      cursor: pointer;
      opacity: .6;
    }
  }

  &__program-0 {
    background-color: $colorPrimary;
  }

  &__program-1 {
    background-color: $colorGreenMedium;
  }

  &__program-2 {
    background-color: $colorPrimaryDpo;
  }

  +mediaTablet() {
    &__programsList {
      flex-direction: row;
    }
  }
}
</style>
