export const programs = [
  {
    title: "Магистратура",
    degree_id: 3,
  },
  {
    title: "Ординатура",
    degree_id: 1,
  },
  {
    title: "Аспирантура",
    degree_id: 2,
  },
];
