<template>
  <div class="commonTabs">
    <div
      v-for="tab in tabsList"
      :key="tab.label"
      :class="[
        'commonTabs__item',
        {
          commonTabs__item_active: tab.value === currentTab && !tab?.disabled,
          commonTabs__item_disabled: tab?.disabled,
        },
      ]"
      @click="changeTab(tab.value, tab?.disabled)"
    >
      {{ tab.label }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { CommonTabsProps } from "./types";

export default defineComponent({
  name: "CommonTabs",

  props: {
    tabsList: {
      type: Array as PropType<CommonTabsProps["tabsList"]>,
      default: () => [],
      required: true,
    },
    currentTab: {
      type: String as PropType<CommonTabsProps["currentTab"]>,
      required: true,
    },
  },

  setup(_, { emit }) {
    const changeTab = (value: any, isDisabled?: boolean) => {
      if (!isDisabled) {
        emit("changeTab", value);
      }
    };

    return {
      changeTab,
    };
  },
});
</script>

<style lang="stylus" scoped>
.commonTabs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    flex-grow: 1;
    height: 40px;
    cursor: pointer;
    transition: all 0.1s;
    text-decoration: none;
    text-wrap: wrap;
    text-align: center;
    getFontText();
    border-bottom: 2px solid $colorDarkGrey;

    &:hover {
      border-bottom: 4px solid $colorSecond;
    }

    &_active {
      font-weight: bold;
      border-bottom: 4px solid $colorPrimary;
    }

    &_disabled {
      &:hover {
        border-bottom: 2px solid $colorDarkGrey;
      }

      opacity: .6;
    }
  }

  +mediaTablet() {
    &__item {
      &:hover {
        border-bottom: .4vw solid $colorSecond;
      }

      &_active {
        font-weight: bold;
        border-bottom: .4vw solid $colorPrimary;
      }

      &_disabled {
        &:hover {
          border-bottom: 2px solid $colorDarkGrey;
        }

        cursor: default;
        opacity: .6;
      }
    }
  }
}

.dpo .commonTabs {
  &__item {
    &:hover {
      border-bottom: 4px solid $colorSecondDpo;
    }

    &_active {
      font-weight: bold;
      border-bottom: 4px solid $colorPrimaryDpo;
    }
  }

  +mediaTablet() {
    &__item {
      &:hover {
        border-bottom: .4vw solid $colorSecondDpo;
      }

      &_active {
        font-weight: bold;
        border-bottom: .4vw solid $colorPrimaryDpo;
      }
    }
  }
}
</style>
