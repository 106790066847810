<template>
  <header class="magistracy-header">
    <nav class="navbar">
      <router-link class="navbar-brand" :to="{ name: RouteName.HOME }">
        <svg class="magistracy__logo">
          <use xlink:href="#logo-white-icon--sprite" />
        </svg>
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#magistracyMenu"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="magistracyMenu">
        <ul class="navbar-nav">
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY }"
            >
              Магистратура
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY_ABOUT,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY_ABOUT }"
            >
              О программе
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY_PROGRAM,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY_PROGRAM }"
            >
              Структура программы
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY__ENTRANCE,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY__ENTRANCE }"
            >
              Приемная кампания
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY_DOCUMENTS,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY_DOCUMENTS }"
            >
              Документы
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY_CONTACTS,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY_CONTACTS }"
            >
              Контакты
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </header>
  <main>
    <div class="magistracy-page">
      <div class="container">
        <div class="page-content">
          <h1 class="page-title">О программе</h1>
          <div class="h4 mb-5">
            {{ magistracyData?.desc?.header }}
          </div>

          <div class="mb-5">
            <h2 class="h3 font-weight-bolder mb-4">Цель программы</h2>
            <ul class="check-list lead text-uppercase">
              <li>
                Освоить ключевые digital-технологии для трансформации
                медицинских организаций;
              </li>
              <li>
                Изучить лучшие практики лидеров цифровизации здравоохранения.
              </li>
            </ul>
          </div>

          <div class="lead mb-5">
            <p>
              <b class="text-secondary">Мы готовим</b> лидеров цифровой
              трансформации здравоохранения, способных грамотно ставить и решать
              задачи по оптимизации информационных систем и технологий.
            </p>
            <p>
              <b class="text-secondary">Мы даём</b> полный набор инструментов
              для разработки эффективных информационных систем и применения
              digital-технологий в здравоохранении.
            </p>
            <p>
              <b class="text-secondary">Мы учим</b> находить и применять лучшие
              практики для цифровой трансформации здравоохранения.
            </p>
          </div>

          <div class="intro__invite">
            <h3 class="mb-4">
              Развивайтесь в цифровой модели здравоохранения Москвы
            </h3>
            <div class="row">
              <div class="invite-col">
                <img src="img/invite-point.svg" alt="" />
                <span>Постройте карьеру в&nbsp;IT в&nbsp;здравоохранении</span>
              </div>
              <div class="invite-col">
                <img src="img/invite-point.svg" alt="" />
                <span>Выбирайте новый уровень в&nbsp;профессии</span>
              </div>
              <div class="invite-col">
                <img src="img/invite-point.svg" alt="" />
                <span
                  >Станьте лидерами в&nbsp;цифровизации здравоохранения</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="intro__advantages magistracy-secton">
      <div class="container">
        <h2 class="magistracy-title">Преимущества</h2>
        <div class="advantages-row">
          <div
            class="advantages-col"
            v-for="item in magistracyData?.advantages?.about"
            :key="item"
          >
            <div class="advantage-card">
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="magistracy-skills">
      <div class="skills__body">
        <h2 class="magistracy-title">Вы научитесь</h2>
        <div class="skills__row">
          <div
            class="skills__item"
            v-for="item in magistracyData?.skills"
            :key="item"
          >
            <div class="skill">
              {{ item }}
            </div>
          </div>
        </div>
        <div class="skills__note">
          Вы сможете сразу интегрировать полученные знания в текущую работу!
        </div>
      </div>
    </section>

    <section class="magistracy-career">
      <h2 class="magistracy-title">
        Возможности карьеры после окончания программы
      </h2>
      <div class="row">
        <div
          class="career__item"
          v-for="item in magistracyData?.career"
          :key="item"
        >
          <div class="career-head">
            <svg class="magistracy__icon">
              <use xlink:href="#admin--sprite" />
            </svg>
            {{ item?.title }}
          </div>
          <div class="career-body">
            {{ item?.desc }}
          </div>
        </div>
      </div>
    </section>
    <section class="magistracy-training">
      <div class="container">
        <h2 class="magistracy-title">Как учиться в онлайн магистратуре</h2>
        <div class="training">
          <div
            class="training__item"
            v-for="item in magistracyData?.how_learn_going"
            :key="item?.title"
          >
            <div class="training-card">
              <div class="training-head">{{ item?.title }}</div>
              <div class="training-body">{{ item?.desc }}</div>
            </div>
          </div>
        </div>

        <a
          href="#magistracyRegister"
          class="btn btn-magistracy"
          data-toggle="modal"
          >Подать заявку</a
        >
      </div>
    </section>
  </main>
  <footer class="footer-magistracy">
    <div class="container">
      <div class="contacts-title">Приемная комиссия</div>
      <div class="contacts">
        <div class="contacts__item">
          <div class="contact-icon">
            <svg class="magistracy__icon">
              <use xlink:href="#clock--sprite" />
            </svg>
          </div>
          <div class="contact-name">Часы работы</div>
          <div class="contact-text">
            <p>{{ magistracyData?.contacts?.works_time }}</p>
          </div>
        </div>
        <div class="contacts__item">
          <div class="contact-icon">
            <svg class="magistracy__icon">
              <use xlink:href="#email--sprite" />
            </svg>
          </div>
          <div class="contact-name">Почта</div>
          <div class="contact-text">
            <p v-for="email in magistracyData?.contacts?.emails" :key="email">
              <a :href="`mailto:${email}`">
                {{ email }}
              </a>
            </p>
          </div>
        </div>
        <div class="contacts__item">
          <div class="contact-icon">
            <svg class="magistracy__icon">
              <use xlink:href="#phone--sprite" />
            </svg>
          </div>
          <div class="contact-name">Телефон</div>
          <div class="contact-text">
            <p v-for="phone in magistracyData?.contacts?.phones" :key="phone">
              {{ phone }}
            </p>
          </div>
        </div>
        <div class="contacts__item">
          <div class="contact-icon">
            <svg class="magistracy__icon">
              <use xlink:href="#map--sprite" />
            </svg>
          </div>
          <div class="contact-name">Адрес</div>
          <div class="contact-text">
            <p>
              {{ magistracyData?.contacts?.address }}
            </p>
            <p>
              <router-link :to="{ name: RouteName.HOME_MAGISTRACY_CONTACTS }">
                Карта
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="footer__copyright">&copy; ГБУ «НИИОЗММ ДЗМ», 2024</div>
  </footer>
  <div class="modal fade" id="magistracyRegister" tabindex="-1">
    <div class="modal-dialog magistracy-register">
      <div class="modal-content">
        <div class="modal-body">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
          <h3 class="modal-title">Подать заявку</h3>

          <div class="register-intro js-register-types">
            <div class="buttons-group">
              <div class="buttons-item">
                <button
                  type="button"
                  class="btn btn-register"
                  data-tab="#personRegister"
                >
                  <svg class="svg-icon">
                    <use xlink:href="img/sprite.svg#person"></use>
                  </svg>
                  <div class="card-body">Лично</div>
                </button>
              </div>
              <div class="buttons-item">
                <button
                  type="button"
                  class="btn btn-register"
                  data-tab="#webformRegister"
                >
                  <svg class="svg-icon">
                    <use xlink:href="img/sprite.svg#webform"></use>
                  </svg>
                  <div class="card-body">
                    Через Личный кабинет на&nbsp;сайте
                  </div>
                </button>
              </div>
              <div class="buttons-item">
                <button
                  type="button"
                  class="btn btn-register"
                  data-tab="#postalRegister"
                >
                  <svg class="svg-icon">
                    <use xlink:href="img/sprite.svg#postal"></use>
                  </svg>
                  <div class="card-body">Через операторов почтовой связи</div>
                </button>
              </div>
            </div>
          </div>

          <div class="register-tabs js-register-tabs">
            <div class="nav">
              <a class="nav-link" data-toggle="tab" href="#personRegister">
                <svg class="svg-icon">
                  <use xlink:href="img/sprite.svg#person"></use>
                </svg>
                Лично
              </a>
              <a class="nav-link" data-toggle="tab" href="#webformRegister">
                <svg class="svg-icon">
                  <use xlink:href="img/sprite.svg#webform"></use>
                </svg>
                На сайте
              </a>
              <a class="nav-link" data-toggle="tab" href="#postalRegister">
                <svg class="svg-icon">
                  <use xlink:href="img/sprite.svg#postal"></use>
                </svg>
                По почте
              </a>
            </div>
            <div class="tab-content">
              <div class="tab-pane" id="personRegister" role="tabpanel">
                <p>
                  <b>Вы можете подать документы лично по адресу:</b>
                  г.&nbsp;Москва, ул.&nbsp;Киевская, д.&nbsp;20, кабинет&nbsp;8.
                </p>
                <p><b>Часы работы:</b> Пн-Пт 09:00 – 17:30</p>
                <p>
                  <b
                    >Список документов для поступления на&nbsp;программу
                    магистратуры:</b
                  >
                </p>
                <ol>
                  <li>
                    <a
                      href="https://niioz.ru/upload/iblock/000/000ec0855d5336e3befb60fcf61f82a1.docx"
                      tarhet="_blank"
                      >Заявление на обучение</a
                    >
                  </li>
                  <li>
                    Копия паспорта (главная и регистрация по&nbsp;месту
                    жительства)
                  </li>
                  <li>
                    Копия диплома о высшем образовании с&nbsp;приложениями
                  </li>
                  <li>Копия СНИЛС</li>
                  <li>ИНН (копия или номер)</li>
                  <li>
                    <a
                      href="https://niioz.ru/upload/iblock/1b0/1b02e815b990b92a660bf53a78f855f2.docx"
                      target="_blank"
                      >Согласие на обработку персональных данных</a
                    >
                  </li>
                </ol>
                <div class="register-footer">
                  <button type="button" class="btn" data-dismiss="modal">
                    Понятно
                  </button>
                </div>
              </div>

              <div class="tab-pane" id="webformRegister" role="tabpanel">
                <p>
                  Для подачи документов в магистратуру Вы будете перенаправлены
                  на сайт Государственного бюджетного учреждения
                  Научно-исследовательский институт организации здравоохранения
                  и медицинского менеджмента Департамента здравоохранения г.
                  Москвы (ГБУ «НИИОЗММ ДЗМ»).
                </p>
                <ol class="divided">
                  <li>
                    Зарегистрируйтесь на сайте
                    <a href="https://niioz.ru/" target="_blank">www.niioz.ru</a>
                  </li>
                  <li>Подтвердите электронную почту по ссылке из письма</li>
                  <li>
                    Войдите в Личный кабинет в раздел
                    <a
                      href="https://niioz.ru/personal/magistracy/"
                      target="_blank"
                      >«Подать документы в магистратуру»</a
                    >
                  </li>
                  <li>Заполните форму, нажмите кнопку «Сохранить»</li>
                </ol>
                <p>
                  <a href="#" target="_blank">Скачать подробную инструкцию</a>
                </p>
                <div class="register-footer">
                  <a
                    href="https://niioz.ru/obrazovanie/magistratura/"
                    class="btn"
                    target="_blank"
                    >Продолжить</a
                  >
                </div>
              </div>

              <div class="tab-pane" id="postalRegister" role="tabpanel">
                <p>
                  Вы можете направить документы через оператора почтовой связи,
                  например, Почтой России.
                </p>
                <p>
                  <b>Адрес:</b> ул. Большая Татарская, д.&nbsp;30, помещение
                  1/2, Москва, 115184.
                </p>
                <p>
                  <b
                    >Список документов для поступления на&nbsp;программу
                    магистратуры:</b
                  >
                </p>
                <ol>
                  <li>
                    <a
                      href="https://niioz.ru/upload/iblock/000/000ec0855d5336e3befb60fcf61f82a1.docx"
                      target="_blank"
                      >Заявление на обучение</a
                    >
                  </li>
                  <li>
                    Копия паспорта (главная и регистрация по&nbsp;месту
                    жительства)
                  </li>
                  <li>
                    Копия диплома о высшем образовании с&nbsp;приложениями
                  </li>
                  <li>Копия СНИЛС</li>
                  <li>ИНН (копия или номер)</li>
                  <li>
                    <a
                      href="https://niioz.ru/upload/iblock/1b0/1b02e815b990b92a660bf53a78f855f2.docx"
                      target="_blank"
                      >Согласие на обработку персональных данных</a
                    >
                  </li>
                </ol>
                <div class="register-footer">
                  <button type="button" class="btn" data-dismiss="modal">
                    Понятно
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
} from "vue";
import { RouteName } from "@/constants";
import { useRoute } from "vue-router";
import store from "@/store";

export default defineComponent({
  name: "HomeMagistracyAboutPage",

  components: {},

  setup() {
    const route = useRoute();

    const magistracyData = computed(
      () =>
        store.getters.programsStore.magistracy?.length &&
        store.getters.programsStore.magistracy[0]
    );

    const setMainCss = () => {
      const link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.id = "main-css";

      document.head.appendChild(link);

      link.href = "/assets/css/main.css";
    };

    const removeMainCss = () => {
      document.getElementById("main-css")?.remove();
    };

    onBeforeMount(() => setMainCss());

    onBeforeUnmount(() => removeMainCss());

    onMounted(() => {
      store.dispatch.programsStore.getPrograms({ is_magistracy: true });
    });

    return {
      RouteName,
      route,
      magistracyData,
    };
  },
});
</script>

<style lang="stylus" scoped>
.magistracy__logo {
  width: 206px;
  height: 40px;
  min-width: 206px;
}
.navbar-collapse {
  background-color: transparent;
}
.magistracy-page::before {
  background-image: url("@/assets/img/magistracy-hand.png");
}
.magistracy-skills:before {
  background-image: url("@/assets/img/magistracy-bg.png");
}
</style>
